<template>
    <div class="padding-container">
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center no-flex">
                <h3 class="fw-bold">Daftar Visit Mitra</h3>
            </div>
            <div class="d-flex mt-4">
                <div class="me-3">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Cari nama perusahaan/driver"
                            v-model="req.search"
                            @input="changeSearch()"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div>
                    <a
                        href="/trawltruck/mitra/create"
                        class="btn btn-green btn-with-search px-4"
                    >
                        Tambah Data Visit
                    </a>
                </div>
                <div class="me-auto m-mt-1 ms-3">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-4">
                        <label class="fw-bold mb-2">Jenis Mitra</label>
                        <multiselect
                            v-model="req.type"
                            :options="types"
                            label="name"
                            track-by="type"
                            placeholder="Pilih Jenis Mitra"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4">
                        <label class="fw-bold mb-2">Tanggal Dibuat</label>
                        <date-picker
                            v-model="req.created_at"
                            type="date"
                            value-type="format"
                            class="w-100"
                            format="DD MMMM YYYY"
                            :clearable="false"
                            placeholder="Tanggal Dibuat"
                        >
                        </date-picker>
                    </div>
                    <div class="col-md-4">
                        <label class="fw-bold mb-2">List VM</label>
                        <multiselect
                            v-model="req.vmList"
                            :options="vmLists"
                            label="name"
                            track-by="id"
                            placeholder="Pilih VM"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-3">
                        <button
                            class="btn btn-outline-black px-5 me-2"
                            :disabled="
                                req.type == null &&
                                req.created_at == '' &&
                                req.vmList == null
                            "
                            @click="clearFilter()"
                        >
                            Clear
                        </button>
                        <button
                            class="btn btn-green px-5"
                            :disabled="
                                req.type == null &&
                                req.created_at == '' &&
                                req.vmList == null
                            "
                            @click="filter()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div class="table-responsive-custom" v-else>
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">Jenis Mitra</th>
                            <th class="text-center" scope="col">
                                Nama Perusahaan/Driver
                            </th>
                            <th class="text-center" scope="col">
                                Nomor Telepon
                            </th>
                            <th class="text-center" scope="col">
                                Jumlah Armada
                            </th>
                            <th class="text-center" scope="col">
                                Kota/Kabupaten
                            </th>
                            <th class="text-center" scope="col">VM Truk</th>
                            <th class="text-center" scope="col">
                                Tanggal Dibuat
                            </th>
                            <th class="text-center" scope="col">
                                Terakhir diedit
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in list.list_data"
                            :key="index"
                        >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">
                                <small
                                    class="tag radius px-4 capitalize"
                                    v-bind:class="{
                                        pending: data.type == 'individual',
                                        resubmit: data.type != 'corporate',
                                    }"
                                >
                                    {{ data.type }}
                                </small>
                            </td>
                            <td>
                                <a
                                    :href="`/trawltruck/mitra/detail/${data.id}`"
                                    class="text-link capitalize"
                                >
                                    {{ data.name_visit }}
                                </a>
                            </td>
                            <td class="text-center">
                                {{ data.phone_visit }}
                            </td>
                            <td class="text-center">
                                {{ data.total_fleet }}
                            </td>
                            <td>{{ data.regency_name }}</td>
                            <td>{{ data.vm_name ? data.vm_name : "-" }}</td>
                            <td class="text-center">
                                {{ data.created_at }}
                            </td>
                            <td class="text-center">
                                {{ data.updated_at }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center mt-5" v-if="list.list_data.length == 0">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
            <div class="d-flex" v-if="list.list_data.length > 0">
                <div class="ms-auto">
                    <div class="d-flex mt-4">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="per_page"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <b-pagination-nav
                            v-model="currentPage"
                            :number-of-pages="list.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changePage"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
    components: {
        ListLoader,
        Multiselect,
        DatePicker,
    },
    name: "trawltruckMitra",
    data() {
        return {
            moment: moment,
            isFilter: false,
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page: 10,
            currentPage: 0,
            req: {
                search: "",
                created_at: "",
                type: null,
                vmList: null,
            },
            types: [
                { name: "Individual", type: "individual" },
                { name: "Corporate", type: "corporate" },
            ],
            vmLists: null,
        };
    },
    created() {
        this.getList();
        this.getVm();
    },
    computed: {},
    methods: {
        getVm() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/vm/list?with=deleted`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.vmLists = data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getList() {
            this.is_list_ready = true;
            let payload = {
                search: this.req.search,
                limit: this.per_page,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
            };
            if (this.req.created_at) {
                payload.created_at = moment(this.req.created_at).format(
                    "YYYY-MM-DD"
                );
            }
            if (this.req.type) {
                payload.type = this.req.type.type;
            }
            if (this.req.vmList) {
                payload.created_by = this.req.vmList.id;
            }
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/partner/truck/vm-report/list`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    this.is_list_ready = false;
                });
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        changePage() {
            this.getList();
        },
        clearFilter() {
            this.req.type = null;
            this.req.created_at = "";
            this.req.vmList = null;
            this.getList();
        },
        filter() {
            this.getList();
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
</style>
