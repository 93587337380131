<template>
    <div class="padding-container">
        <div class="box mt-3">
            <h4 class="mb-0 fw-bold">Set Harga Layanan</h4>
            <div class="row mt-4">
                <div class="col-md-6">
                    <label class="fw-bold mb-1">
                        Jenis Layanan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="service"
                        :options="services"
                        label="label"
                        track-by="id"
                        placeholder="Pilih Jenis Layanan"
                        class="multi-form-custom"
                        :show-labels="false"
                        @input="showSection()"
                    >
                    </multiselect>
                </div>
            </div>
            <hr class="my-5" v-if="!service" />
            <div class="d-flex mt-3" v-if="!service">
                <div class="ms-auto">
                    <button
                        class="btn btn-disabled px-5"
                        @click="checkExisting()"
                        :disabled="isLoading || !allInputCargo"
                    >
                        <span
                            v-if="isLoading"
                            class="spinner-border spinner-border-sm me-2"
                        ></span>
                        Simpan
                    </button>
                </div>
            </div>
            <div class="border-top pt-5 mt-5" v-if="showTrobenCargo">
                <h4 class="mb-0">Kota Asal</h4>
                <div class="row mt-2">
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Kota/Kabupaten Asal
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <multiselect
                            v-model="originRegencyCargo"
                            :options="originRegenciesCargo"
                            label="name"
                            track-by="value"
                            placeholder="Cari kota/kabupaten"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>

                    <hr class="mt-3" />

                    <h4 class="mt-2 mb-0">Kota Penerima</h4>
                    <div class="row mt-2">
                        <div class="col-md-6 mt-2">
                            <label class="fw-bold mb-1">
                                Kota/Kabupaten
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <multiselect
                                v-model="regency"
                                :options="regencies"
                                label="name"
                                track-by="id"
                                placeholder="Cari kota/kabupaten"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-md-6 mt-2">
                            <label class="fw-bold mb-1">
                                Kecamatan
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <multiselect
                                v-model="district"
                                :options="districts"
                                label="name"
                                track-by="id"
                                placeholder="Pilih kecamatan"
                                class="multi-form-custom"
                                :show-labels="false"
                                :disabled="!regency"
                            >
                            </multiselect>
                        </div>
                        <div class="col-md-6 mt-2">
                            <label class="fw-bold mb-1">
                                Kelurahan
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <multiselect
                                v-model="subDistrict"
                                :options="subDistricts"
                                label="name"
                                track-by="id"
                                placeholder="Pilih Kelurahan"
                                class="multi-form-custom"
                                :show-labels="false"
                                :disabled="!district"
                            >
                            </multiselect>
                        </div>
                    </div>

                    <hr class="mt-3" />

                    <h4 class="mt-2 mb-0">Harga Pengiriman</h4>
                    <div class="row mt-2">
                        <div class="col-md-6 mt-2">
                            <label class="fw-bold mb-1">
                                Jenis Pengiriman
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <multiselect
                                v-model="delivery"
                                :options="deliveries"
                                label="label"
                                track-by="id"
                                placeholder="Pilih jenis pengiriman"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div
                            class="col-md-6 mt-2"
                            v-if="delivery && delivery.id === 'bike'"
                        >
                            <label class="fw-bold mb-1">
                                Kapasitas Motor
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <multiselect
                                v-model="capacity"
                                :options="capacities"
                                label="label"
                                track-by="id"
                                placeholder="Pilih kapasitas motor"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-md-6 mt-2">
                            <label class="fw-bold mb-1">
                                Harga Pengiriman
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <div class="input-group mb-0">
                                <span class="input-group-text">Rp</span>
                                <input
                                    v-model="formattedPriceCargo"
                                    type="text"
                                    class="form-control form-phone"
                                    placeholder="Masukkan harga pengiriman"
                                    @input="handleInput"
                                />
                                <span
                                    class="input-group-text"
                                    v-if="delivery && delivery.id === 'cargo'"
                                    >/Kg</span
                                >
                            </div>
                        </div>
                        <div class="d-flex mt-3">
                            <div class="ms-auto">
                                <button
                                    :class="[
                                        'btn',
                                        !allInputCargo
                                            ? 'btn-disabled'
                                            : 'btn-green',
                                        'px-5',
                                    ]"
                                    @click="checkExisting()"
                                    :disabled="isLoading || !allInputCargo"
                                >
                                    <span
                                        v-if="isLoading"
                                        class="spinner-border spinner-border-sm me-2"
                                    ></span>
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border-top pt-5 mt-5" v-if="showTrobenTruck">
                <div class="row mt-2">
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Kota Asal
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <multiselect
                            v-model="originRegencyTruck"
                            :options="originRegenciesTruck"
                            label="name"
                            track-by="value"
                            placeholder="Cari kota asal"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Kota Tujuan
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <multiselect
                            v-model="destRegencyTruck"
                            :options="regencies"
                            label="name"
                            track-by="id"
                            placeholder="Cari kota tujuan"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                </div>
                <hr class="mt-3" />
                <div class="row mt-2">
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Jenis Armada
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <multiselect
                            v-model="fleet"
                            :options="fleets"
                            label="name"
                            track-by="code"
                            placeholder="Pilih jenis armada"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Harga Sewa
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <div class="input-group mb-0">
                            <span class="input-group-text">Rp</span>
                            <input
                                v-model="formattedPriceTruck"
                                type="text"
                                class="form-control form-phone"
                                placeholder="Masukkan harga pengiriman"
                                @input="handleInputTruck"
                            />
                        </div>
                    </div>
                    <div class="d-flex mt-3">
                        <div class="ms-auto">
                            <button
                                :class="[
                                    'btn',
                                    !allInputTruck
                                        ? 'btn-disabled'
                                        : 'btn-green',
                                    'px-5',
                                ]"
                                :disabled="isLoading || !allInputTruck"
                                @click="checkExistingTruck()"
                            >
                                <span
                                    v-if="isLoading"
                                    class="spinner-border spinner-border-sm me-2"
                                ></span>
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border-top pt-5 mt-5" v-if="showTrobenCarier">
                <div class="row mt-2">
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Kota Asal
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <multiselect
                            v-model="originRegencyCarier"
                            :options="regencies"
                            label="name"
                            track-by="id"
                            placeholder="Cari kota asal"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Kota Tujuan
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <multiselect
                            v-model="destRegencyCarier"
                            :options="regencies"
                            label="name"
                            track-by="id"
                            placeholder="Cari kota tujuan"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                </div>
                <hr class="mt-3" />
                <div class="row mt-2">
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Kategori Mobil
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <multiselect
                            v-model="carCategory"
                            :options="carCategories"
                            label="label"
                            track-by="id"
                            placeholder="Pilih kategori mobil"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Jenis Layanan
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <multiselect
                            v-model="serviceCarier"
                            :options="serviceCariers"
                            label="label"
                            track-by="id"
                            placeholder="Pilih jenis layanan"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div
                        class="col-md-6 mt-2"
                        v-if="
                            serviceCarier && serviceCarier.id === 'door_to_door'
                        "
                    >
                        <label class="fw-bold mb-1">
                            Sub Layanan
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <multiselect
                            v-model="subServiceCarier"
                            :options="subServiceCariers"
                            label="label"
                            track-by="id"
                            placeholder="Pilih sub layanan"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-2">
                        <label class="fw-bold mb-1">
                            Harga Pengiriman
                            <span class="text-danger fw-bold">*</span>
                        </label>
                        <div class="input-group mb-0">
                            <span class="input-group-text">Rp</span>
                            <input
                                v-model="formattedPriceCarier"
                                type="text"
                                class="form-control form-phone"
                                placeholder="Masukkan harga pengiriman"
                                @input="handleInputCarier"
                            />
                        </div>
                    </div>
                    <div class="d-flex mt-3">
                        <div class="ms-auto">
                            <button
                                :class="[
                                    'btn',
                                    !allInputCarier
                                        ? 'btn-disabled'
                                        : 'btn-green',
                                    'px-5',
                                ]"
                                @click="checkExistingCarier()"
                                :disabled="isLoading || !allInputCarier"
                            >
                                <span
                                    v-if="isLoading"
                                    class="spinner-border spinner-border-sm me-2"
                                ></span>
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/check-new.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Harga Layanan Berhasil Diubah
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        @click="modalSuccess = false"
                        href="/service/setharga-layanan"
                        class="btn btn-green w-100 btn-lg"
                        >OK</a
                    >
                </div>
            </div>
        </div>

        <div class="modal-vue" v-if="modalExistingCargo">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="81"
                        height="80"
                        viewBox="0 0 81 80"
                        fill="none"
                    >
                        <rect
                            x="0.5"
                            width="80"
                            height="80"
                            rx="40"
                            fill="#FB9727"
                        />
                        <path
                            d="M40.7567 64.8723C39.8928 64.8723 39.0484 64.6161 38.3301 64.1362C37.6119 63.6562 37.0521 62.9741 36.7215 62.176C36.3909 61.3779 36.3044 60.4997 36.4729 59.6525C36.6415 58.8052 37.0574 58.027 37.6683 57.4161C38.2791 56.8053 39.0573 56.3893 39.9046 56.2208C40.7518 56.0523 41.63 56.1388 42.4281 56.4693C43.2262 56.7999 43.9084 57.3597 44.3883 58.078C44.8682 58.7963 45.1244 59.6407 45.1244 60.5046C45.1244 61.6629 44.6642 62.7739 43.8451 63.593C43.026 64.4121 41.9151 64.8723 40.7567 64.8723ZM45.5 20.9442L44.2465 47.5871C44.2465 48.5138 43.8783 49.4026 43.2231 50.0579C42.5678 50.7131 41.679 51.0813 40.7523 51.0813C39.8256 51.0813 38.9369 50.7131 38.2816 50.0579C37.6263 49.4026 37.2582 48.5138 37.2582 47.5871L36.0046 20.9551C35.9765 20.3187 36.0767 19.6832 36.2994 19.0864C36.5221 18.4895 36.8627 17.9437 37.3008 17.4813C37.739 17.0188 38.2657 16.6494 38.8497 16.3949C39.4337 16.1404 40.0629 16.0061 40.6999 16H40.7458C41.3871 15.9997 42.0218 16.1293 42.6117 16.381C43.2016 16.6327 43.7343 17.0014 44.1778 17.4646C44.6213 17.9279 44.9663 18.4762 45.1921 19.0765C45.4178 19.6768 45.5196 20.3166 45.4913 20.9573L45.5 20.9442Z"
                            fill="#FBFBFB"
                        />
                    </svg>
                    <h4 class="mb-0 fw-bold mt-4">
                        Harga Layanan Yang Tersedia Saat Ini,
                        {{ currency(existingPrice) }}
                    </h4>
                    <p class="text-muted">
                        Apakah anda ingin mengubah harga dengan yang baru?
                    </p>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6 my-3">
                        <button
                            @click="modalExistingCargo = false"
                            class="btn bg-transparent border border-red w-100 btn-lg"
                        >
                            Kembali
                        </button>
                    </div>
                    <div class="col-md-6 my-3">
                        <button
                            @click="storeCargo()"
                            class="btn btn-green w-100 btn-lg"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Ubah Harga
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue" v-if="modalExistingTruck">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="81"
                        height="80"
                        viewBox="0 0 81 80"
                        fill="none"
                    >
                        <rect
                            x="0.5"
                            width="80"
                            height="80"
                            rx="40"
                            fill="#FB9727"
                        />
                        <path
                            d="M40.7567 64.8723C39.8928 64.8723 39.0484 64.6161 38.3301 64.1362C37.6119 63.6562 37.0521 62.9741 36.7215 62.176C36.3909 61.3779 36.3044 60.4997 36.4729 59.6525C36.6415 58.8052 37.0574 58.027 37.6683 57.4161C38.2791 56.8053 39.0573 56.3893 39.9046 56.2208C40.7518 56.0523 41.63 56.1388 42.4281 56.4693C43.2262 56.7999 43.9084 57.3597 44.3883 58.078C44.8682 58.7963 45.1244 59.6407 45.1244 60.5046C45.1244 61.6629 44.6642 62.7739 43.8451 63.593C43.026 64.4121 41.9151 64.8723 40.7567 64.8723ZM45.5 20.9442L44.2465 47.5871C44.2465 48.5138 43.8783 49.4026 43.2231 50.0579C42.5678 50.7131 41.679 51.0813 40.7523 51.0813C39.8256 51.0813 38.9369 50.7131 38.2816 50.0579C37.6263 49.4026 37.2582 48.5138 37.2582 47.5871L36.0046 20.9551C35.9765 20.3187 36.0767 19.6832 36.2994 19.0864C36.5221 18.4895 36.8627 17.9437 37.3008 17.4813C37.739 17.0188 38.2657 16.6494 38.8497 16.3949C39.4337 16.1404 40.0629 16.0061 40.6999 16H40.7458C41.3871 15.9997 42.0218 16.1293 42.6117 16.381C43.2016 16.6327 43.7343 17.0014 44.1778 17.4646C44.6213 17.9279 44.9663 18.4762 45.1921 19.0765C45.4178 19.6768 45.5196 20.3166 45.4913 20.9573L45.5 20.9442Z"
                            fill="#FBFBFB"
                        />
                    </svg>
                    <h4 class="mb-0 fw-bold mt-4">
                        Harga Layanan Yang Tersedia Saat Ini,
                        {{ currency(existingPriceTruck) }}
                    </h4>
                    <p class="text-muted">
                        Apakah anda ingin mengubah harga dengan yang baru?
                    </p>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6 my-3">
                        <button
                            @click="modalExistingTruck = false"
                            class="btn bg-transparent border border-red w-100 btn-lg"
                        >
                            Kembali
                        </button>
                    </div>
                    <div class="col-md-6 my-3">
                        <button
                            @click="storeTruck()"
                            class="btn btn-green w-100 btn-lg"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Ubah Harga
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue" v-if="modalExistingCarier">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="81"
                        height="80"
                        viewBox="0 0 81 80"
                        fill="none"
                    >
                        <rect
                            x="0.5"
                            width="80"
                            height="80"
                            rx="40"
                            fill="#FB9727"
                        />
                        <path
                            d="M40.7567 64.8723C39.8928 64.8723 39.0484 64.6161 38.3301 64.1362C37.6119 63.6562 37.0521 62.9741 36.7215 62.176C36.3909 61.3779 36.3044 60.4997 36.4729 59.6525C36.6415 58.8052 37.0574 58.027 37.6683 57.4161C38.2791 56.8053 39.0573 56.3893 39.9046 56.2208C40.7518 56.0523 41.63 56.1388 42.4281 56.4693C43.2262 56.7999 43.9084 57.3597 44.3883 58.078C44.8682 58.7963 45.1244 59.6407 45.1244 60.5046C45.1244 61.6629 44.6642 62.7739 43.8451 63.593C43.026 64.4121 41.9151 64.8723 40.7567 64.8723ZM45.5 20.9442L44.2465 47.5871C44.2465 48.5138 43.8783 49.4026 43.2231 50.0579C42.5678 50.7131 41.679 51.0813 40.7523 51.0813C39.8256 51.0813 38.9369 50.7131 38.2816 50.0579C37.6263 49.4026 37.2582 48.5138 37.2582 47.5871L36.0046 20.9551C35.9765 20.3187 36.0767 19.6832 36.2994 19.0864C36.5221 18.4895 36.8627 17.9437 37.3008 17.4813C37.739 17.0188 38.2657 16.6494 38.8497 16.3949C39.4337 16.1404 40.0629 16.0061 40.6999 16H40.7458C41.3871 15.9997 42.0218 16.1293 42.6117 16.381C43.2016 16.6327 43.7343 17.0014 44.1778 17.4646C44.6213 17.9279 44.9663 18.4762 45.1921 19.0765C45.4178 19.6768 45.5196 20.3166 45.4913 20.9573L45.5 20.9442Z"
                            fill="#FBFBFB"
                        />
                    </svg>
                    <h4 class="mb-0 fw-bold mt-4">
                        Harga Layanan Yang Tersedia Saat Ini,
                        {{ currency(existingPriceCarier) }}
                    </h4>
                    <p class="text-muted">
                        Apakah anda ingin mengubah harga dengan yang baru?
                    </p>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6 my-3">
                        <button
                            @click="modalExistingCarier = false"
                            class="btn bg-transparent border border-red w-100 btn-lg"
                        >
                            Kembali
                        </button>
                    </div>
                    <div class="col-md-6 my-3">
                        <button
                            @click="storeCarier()"
                            class="btn btn-green w-100 btn-lg"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Ubah Harga
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue" v-if="modalConfirmCarier">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="81"
                        height="80"
                        viewBox="0 0 81 80"
                        fill="none"
                    >
                        <rect
                            x="0.5"
                            width="80"
                            height="80"
                            rx="40"
                            fill="#FB9727"
                        />
                        <path
                            d="M40.7567 64.8723C39.8928 64.8723 39.0484 64.6161 38.3301 64.1362C37.6119 63.6562 37.0521 62.9741 36.7215 62.176C36.3909 61.3779 36.3044 60.4997 36.4729 59.6525C36.6415 58.8052 37.0574 58.027 37.6683 57.4161C38.2791 56.8053 39.0573 56.3893 39.9046 56.2208C40.7518 56.0523 41.63 56.1388 42.4281 56.4693C43.2262 56.7999 43.9084 57.3597 44.3883 58.078C44.8682 58.7963 45.1244 59.6407 45.1244 60.5046C45.1244 61.6629 44.6642 62.7739 43.8451 63.593C43.026 64.4121 41.9151 64.8723 40.7567 64.8723ZM45.5 20.9442L44.2465 47.5871C44.2465 48.5138 43.8783 49.4026 43.2231 50.0579C42.5678 50.7131 41.679 51.0813 40.7523 51.0813C39.8256 51.0813 38.9369 50.7131 38.2816 50.0579C37.6263 49.4026 37.2582 48.5138 37.2582 47.5871L36.0046 20.9551C35.9765 20.3187 36.0767 19.6832 36.2994 19.0864C36.5221 18.4895 36.8627 17.9437 37.3008 17.4813C37.739 17.0188 38.2657 16.6494 38.8497 16.3949C39.4337 16.1404 40.0629 16.0061 40.6999 16H40.7458C41.3871 15.9997 42.0218 16.1293 42.6117 16.381C43.2016 16.6327 43.7343 17.0014 44.1778 17.4646C44.6213 17.9279 44.9663 18.4762 45.1921 19.0765C45.4178 19.6768 45.5196 20.3166 45.4913 20.9573L45.5 20.9442Z"
                            fill="#FBFBFB"
                        />
                    </svg>
                    <h4 class="mb-0 fw-bold mt-4">
                        Harga Layanan Akan Disimpan
                    </h4>
                    <p class="text-muted">
                        Apakah anda ingin menyimpan harga layanan ini
                    </p>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6 my-3">
                        <button
                            @click="modalConfirmCarier = false"
                            class="btn bg-transparent border border-red w-100 btn-lg"
                        >
                            Kembali
                        </button>
                    </div>
                    <div class="col-md-6 my-3">
                        <button
                            @click="storeCarier()"
                            class="btn btn-green w-100 btn-lg"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue" v-if="modalConfirmTruck">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="81"
                        height="80"
                        viewBox="0 0 81 80"
                        fill="none"
                    >
                        <rect
                            x="0.5"
                            width="80"
                            height="80"
                            rx="40"
                            fill="#FB9727"
                        />
                        <path
                            d="M40.7567 64.8723C39.8928 64.8723 39.0484 64.6161 38.3301 64.1362C37.6119 63.6562 37.0521 62.9741 36.7215 62.176C36.3909 61.3779 36.3044 60.4997 36.4729 59.6525C36.6415 58.8052 37.0574 58.027 37.6683 57.4161C38.2791 56.8053 39.0573 56.3893 39.9046 56.2208C40.7518 56.0523 41.63 56.1388 42.4281 56.4693C43.2262 56.7999 43.9084 57.3597 44.3883 58.078C44.8682 58.7963 45.1244 59.6407 45.1244 60.5046C45.1244 61.6629 44.6642 62.7739 43.8451 63.593C43.026 64.4121 41.9151 64.8723 40.7567 64.8723ZM45.5 20.9442L44.2465 47.5871C44.2465 48.5138 43.8783 49.4026 43.2231 50.0579C42.5678 50.7131 41.679 51.0813 40.7523 51.0813C39.8256 51.0813 38.9369 50.7131 38.2816 50.0579C37.6263 49.4026 37.2582 48.5138 37.2582 47.5871L36.0046 20.9551C35.9765 20.3187 36.0767 19.6832 36.2994 19.0864C36.5221 18.4895 36.8627 17.9437 37.3008 17.4813C37.739 17.0188 38.2657 16.6494 38.8497 16.3949C39.4337 16.1404 40.0629 16.0061 40.6999 16H40.7458C41.3871 15.9997 42.0218 16.1293 42.6117 16.381C43.2016 16.6327 43.7343 17.0014 44.1778 17.4646C44.6213 17.9279 44.9663 18.4762 45.1921 19.0765C45.4178 19.6768 45.5196 20.3166 45.4913 20.9573L45.5 20.9442Z"
                            fill="#FBFBFB"
                        />
                    </svg>
                    <h4 class="mb-0 fw-bold mt-4">
                        Harga Layanan Akan Disimpan
                    </h4>
                    <p class="text-muted">
                        Apakah anda ingin menyimpan harga layanan ini
                    </p>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6 my-3">
                        <button
                            @click="modalConfirmTruck = false"
                            class="btn bg-transparent border border-red w-100 btn-lg"
                        >
                            Kembali
                        </button>
                    </div>
                    <div class="col-md-6 my-3">
                        <button
                            @click="storeTruck()"
                            class="btn btn-green w-100 btn-lg"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="81"
                        height="80"
                        viewBox="0 0 81 80"
                        fill="none"
                    >
                        <rect
                            x="0.5"
                            width="80"
                            height="80"
                            rx="40"
                            fill="#FB9727"
                        />
                        <path
                            d="M40.7567 64.8723C39.8928 64.8723 39.0484 64.6161 38.3301 64.1362C37.6119 63.6562 37.0521 62.9741 36.7215 62.176C36.3909 61.3779 36.3044 60.4997 36.4729 59.6525C36.6415 58.8052 37.0574 58.027 37.6683 57.4161C38.2791 56.8053 39.0573 56.3893 39.9046 56.2208C40.7518 56.0523 41.63 56.1388 42.4281 56.4693C43.2262 56.7999 43.9084 57.3597 44.3883 58.078C44.8682 58.7963 45.1244 59.6407 45.1244 60.5046C45.1244 61.6629 44.6642 62.7739 43.8451 63.593C43.026 64.4121 41.9151 64.8723 40.7567 64.8723ZM45.5 20.9442L44.2465 47.5871C44.2465 48.5138 43.8783 49.4026 43.2231 50.0579C42.5678 50.7131 41.679 51.0813 40.7523 51.0813C39.8256 51.0813 38.9369 50.7131 38.2816 50.0579C37.6263 49.4026 37.2582 48.5138 37.2582 47.5871L36.0046 20.9551C35.9765 20.3187 36.0767 19.6832 36.2994 19.0864C36.5221 18.4895 36.8627 17.9437 37.3008 17.4813C37.739 17.0188 38.2657 16.6494 38.8497 16.3949C39.4337 16.1404 40.0629 16.0061 40.6999 16H40.7458C41.3871 15.9997 42.0218 16.1293 42.6117 16.381C43.2016 16.6327 43.7343 17.0014 44.1778 17.4646C44.6213 17.9279 44.9663 18.4762 45.1921 19.0765C45.4178 19.6768 45.5196 20.3166 45.4913 20.9573L45.5 20.9442Z"
                            fill="#FBFBFB"
                        />
                    </svg>
                    <h4 class="mb-0 fw-bold mt-4">
                        Harga Layanan Akan Disimpan
                    </h4>
                    <p class="text-muted">
                        Apakah anda ingin menyimpan harga layanan ini
                    </p>
                </div>
                <div class="row mt-4">
                    <div class="col-md-6 my-3">
                        <button
                            @click="modalConfirm = false"
                            class="btn bg-transparent border border-red w-100 btn-lg"
                        >
                            Kembali
                        </button>
                    </div>
                    <div class="col-md-6 my-3">
                        <button
                            @click="storeCargo()"
                            class="btn btn-green w-100 btn-lg"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Api from "../api/Api";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            service: null,
            services: [
                {
                    id: "troben_cargo",
                    label: "Troben Cargo",
                },
                {
                    id: "troben_truk",
                    label: "Troben Truk",
                },
                {
                    id: "troben_carier",
                    label: "Troben Carier",
                },
            ],
            showTrobenCargo: false,
            showTrobenTruck: false,
            showTrobenCarier: false,
            originRegencyCargo: null,
            originRegenciesCargo: [],
            regency: null,
            regencies: [],
            district: null,
            districts: [],
            subDistrict: null,
            subDistricts: [],
            delivery: null,
            deliveries: [
                {
                    id: "cargo",
                    label: "Pengiriman Barang",
                },
                {
                    id: "bike",
                    label: "Pengiriman Motor",
                },
            ],
            price: null,
            capacity: null,
            capacities: [
                {
                    id: "lower",
                    label: "100 CC - 149 CC",
                },
                {
                    id: "middle",
                    label: "150 CC - 249 CC",
                },
                {
                    id: "high",
                    label: "> 250 CC",
                },
            ],
            modalSuccess: false,
            existingPrice: null,
            existingPriceTruck: null,
            modalExistingCargo: false,
            modalExistingTruck: false,
            isLoading: false,
            originRegencyTruck: null,
            originRegenciesTruck: [],
            destRegencyTruck: null,
            fleets: [],
            priceTruck: null,
            fleet: null,
            originRegencyCarier: null,
            destRegencyCarier: null,
            carCategory: null,
            carCategories: [
                {
                    id: "standard",
                    label: "Medium",
                },
                {
                    id: "premium",
                    label: "Premium",
                },
                {
                    id: "super",
                    label: "Super Car",
                },
            ],
            serviceCarier: null,
            serviceCariers: [
                {
                    id: "door_to_door",
                    label: "Door to Door",
                },
                {
                    id: "pool_to_pool",
                    label: "Pool to Pool",
                },
                {
                    id: "port_to_port",
                    label: "Port to Port",
                },
            ],
            subServiceCarier: null,
            subServiceCariers: [
                {
                    id: "selfdrive_carrier_selfdrive",
                    label: "Self Drive - Carrier - Self Drive",
                },
                {
                    id: "towing_vessel_towing",
                    label: "Towing - Vessel - Towing",
                },
                {
                    id: "selfdrive_vessel_selfdrive",
                    label: "Selfdrive - Vessel - Selfdrive",
                },
                {
                    id: "towing_carrier_towing",
                    label: "Towing - Carrier - Towing",
                },
                {
                    id: "full_towing",
                    label: "Full Towing",
                },
            ],
            priceCarier: null,
            existingPriceCarier: null,
            modalExistingCarier: false,
            modalConfirmCarier: false,
            modalConfirmTruck: false,
            modalConfirm: false,
        };
    },
    methods: {
        handleInput(event) {
            const inputValue = event.target.value.replace(/[^\d]/g, "");
            this.formattedPriceCargo = inputValue;
        },
        handleInputTruck(event) {
            const inputValue = event.target.value.replace(/[^\d]/g, "");
            this.formattedPriceTruck = inputValue;
        },
        handleInputCarier(event) {
            const inputValue = event.target.value.replace(/[^\d]/g, "");
            this.formattedPriceCarier = inputValue;
        },
        showSection() {
            this.resetSections();
            if (this.service.id === "troben_cargo") {
                this.getOriginRegencyCargo();
                this.showTrobenCargo = true;
            } else if (this.service.id === "troben_truk") {
                this.getOriginRegencyTruck();
                this.getFleets();
                this.showTrobenTruck = true;
            } else if (this.service.id === "troben_carier") {
                this.showTrobenCarier = true;
            }
        },
        resetSections() {
            this.showTrobenCargo = false;
            this.showTrobenTruck = false;
            this.showTrobenCarier = false;
        },
        getOriginRegencyCargo() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/bypass/price/geo/cargo/origin`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.originRegenciesCargo = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getOriginRegencyTruck() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/bypass/price/geo/truck/origin`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.originRegenciesTruck = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getFleets() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/trawltruck/fleet`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.fleets = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDistrict() {
            if (this.regency) {
                Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/master/geo/district?regency_id=${this.regency.id}`,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        var data = res.data.data;
                        this.districts = data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.districts = [];
                this.district = null;
            }
        },
        getSubDistrict() {
            if (this.district) {
                Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/master/geo/subdistrict?district_id=${this.district.id}`,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        var data = res.data.data;
                        this.subDistricts = data;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.subDistricts = [];
                this.subDistrict = null;
            }
        },
        checkExisting() {
            this.isLoading = true;
            const payloadData = {
                service: this.delivery.id,
                regency: this.originRegencyCargo.value,
                subdistrict_id: this.subDistrict.id,
                price: this.price,
            };

            if (this.delivery.id === "bike") {
                payloadData.cc = this.capacity.id;
            }

            const payload = new URLSearchParams(payloadData).toString();

            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/bypass/price/cargo/exist?${payload}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.isLoading = false;
                    const data = res.data.data;
                    if (data) {
                        this.existingPrice = data.price;
                        this.modalExistingCargo = true;
                    } else {
                        this.modalConfirm = true;
                    }
                })
                .catch((err) => {
                    this.isLoading = false;
                    console.log(err);
                });
        },
        checkExistingTruck() {
            this.isLoading = true;
            var payloadData = {
                regency: this.originRegencyTruck.value,
                destination_regency_id: this.destRegencyTruck.id,
                fleet_type: this.fleet.code,
                price: this.priceTruck,
            };

            const payload = new URLSearchParams(payloadData).toString();

            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/bypass/price/truck/exist?${payload}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.isLoading = false;
                    const data = res.data.data;
                    if (data) {
                        this.existingPriceTruck = data.price;
                        this.modalExistingTruck = true;
                    } else {
                        this.modalConfirmTruck = true;
                    }
                })
                .catch((err) => {
                    this.isLoading = false;
                    console.log(err);
                });
        },
        checkExistingCarier() {
            this.isLoading = true;
            var payloadData = {
                origin_regency_id: this.originRegencyCarier.id,
                destination_regency_id: this.destRegencyCarier.id,
                class: this.carCategory.id,
                service: this.serviceCarier.id,
                price: this.priceCarier,
            };

            if (this.serviceCarier.id === "door_to_door") {
                payloadData.service_option = this.subServiceCarier.id;
            }

            const payload = new URLSearchParams(payloadData).toString();

            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/bypass/price/carier/exist?${payload}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.isLoading = false;
                    const data = res.data.data;
                    if (data) {
                        this.existingPriceCarier = data.price;
                        this.modalExistingCarier = true;
                    } else {
                        this.modalConfirmCarier = true;
                    }
                })
                .catch((err) => {
                    this.isLoading = false;
                    console.log(err);
                });
        },
        storeCargo() {
            this.modalExistingCargo = false;
            this.modalConfirm = false;
            this.isLoading = true;
            var payload = {
                service: this.delivery.id,
                regency: this.originRegencyCargo.value,
                subdistrict_id: this.subDistrict.id,
                price: this.price,
            };
            if (this.delivery.id === "bike") {
                payload.cc = this.capacity.id;
            }
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/bypass/price/cargo`,
                payload,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.modalSuccess = true;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    console.log(err);
                });
        },
        storeTruck() {
            this.modalExistingTruck = false;
            this.modalConfirmTruck = false;
            this.isLoading = true;
            var payload = {
                regency: this.originRegencyTruck.value,
                destination_regency_id: this.destRegencyTruck.id,
                fleet_type: this.fleet.code,
                price: this.priceTruck,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/bypass/price/truck`,
                payload,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.modalSuccess = true;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    console.log(err);
                });
        },
        storeCarier() {
            this.modalExistingCarier = false;
            this.modalConfirmCarier = false;
            this.isLoading = true;
            var payload = {
                origin_regency_id: this.originRegencyCarier.id,
                destination_regency_id: this.destRegencyCarier.id,
                class: this.carCategory.id,
                service: this.serviceCarier.id,
                price: this.priceCarier,
            };

            if (this.serviceCarier.id === "door_to_door") {
                payload.service_option = this.subServiceCarier.id;
            }
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/bypass/price/carier`,
                payload,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.modalSuccess = true;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    console.log(err);
                });
        },
    },
    watch: {
        regency: {
            handler() {
                this.district = null;
                this.subDistrict = null;
                this.getDistrict();
            },
            immediate: true,
        },
        district: {
            handler() {
                this.subDistrict = null;
                this.getSubDistrict();
            },
            immediate: true,
        },
    },
    computed: {
        formattedPriceCargo: {
            get() {
                return this.price
                    ? this.price
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    : "";
            },
            set(value) {
                this.price = value.replace(/\./g, "");
            },
        },
        formattedPriceTruck: {
            get() {
                return this.priceTruck
                    ? this.priceTruck
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    : "";
            },
            set(value) {
                this.priceTruck = value.replace(/\./g, "");
            },
        },
        formattedPriceCarier: {
            get() {
                return this.priceCarier
                    ? this.priceCarier
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    : "";
            },
            set(value) {
                this.priceCarier = value.replace(/\./g, "");
            },
        },
        allInputCargo() {
            const isBikeDelivery = this.delivery === "bike";
            return (
                this.originRegencyCargo &&
                this.regency &&
                this.district &&
                this.subDistrict &&
                this.delivery &&
                (!isBikeDelivery || this.capacity) &&
                this.price
            );
        },
        allInputTruck() {
            return (
                this.originRegencyTruck &&
                this.destRegencyTruck &&
                this.fleet &&
                this.priceTruck
            );
        },
        allInputCarier() {
            const isDoorService = this.serviceCarier === "door_to_door";
            return (
                this.originRegencyCarier &&
                this.destRegencyCarier &&
                this.carCategory &&
                this.serviceCarier &&
                (!isDoorService || this.subServiceCarier) &&
                this.priceCarier
            );
        },
    },
    mounted() {
        this.getRegency();
    },
};
</script>

<style scoped>
.btn-disabled {
    background: #d2d2d5 !important;
    border-color: #d2d2d5 !important;
    color: #fff !important;
}
.border-red {
    border-color: #e60013 !important;
    color: #e60013 !important;
}
</style>
