<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="d-flex align-items-center bd-highlight">
            <div class="flex-grow-1 bd-highlight">
                <h3 class="fw-bold">Pesanan Masuk</h3>
            </div>
        </div>
        <div class="box mt-4">
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <table
                    class="table position-relative mt-4"
                    v-if="list.list_data.length > 0"
                >
                    <thead class="thead">
                        <tr class="tr">
                            <td class="th">No</td>
                            <td class="th">Customer</td>
                            <td class="th">Penerima</td>
                            <td class="th">Tipe Order</td>
                            <td class="th">Layanan</td>
                            <td class="th">Lokasi Tujuan</td>
                            <td class="th">Lokasi Penjemputan</td>
                            <td class="th">Jenis Mobil</td>
                            <td class="th">Harga Mobil</td>
                        </tr>
                    </thead>
                    <tr>
                        <td>
                            <div></div>
                        </td>
                    </tr>
                    <template v-for="(list, index, number) in list.list_data">
                        <tr class="tr border-body" :key="list.id">
                            <td class="td">
                                <b> {{ index + 1 }}. </b>
                            </td>
                            <td class="td">
                                {{ list.customer }}
                            </td>
                            <td class="td">
                                {{ list.penerima }}
                            </td>
                            <td class="td capitalize">
                                {{
                                    (list.service_type || "")
                                        .split("_")
                                        .join(" ")
                                }}
                            </td>
                            <td class="td capitalize">
                                <template v-if="list.service_option">
                                    {{
                                        (list.service_option || "")
                                            .split("_")
                                            .join(" ")
                                    }}
                                </template>
                                <template v-else> - </template>
                            </td>
                            <td class="td" style="width: 170px">
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="list.lokasi_tujuan"
                                >
                                    <span class="limit-one-line">
                                        {{ list.lokasi_tujuan }}
                                    </span>
                                </a>
                            </td>
                            <td class="td" style="width: 170px">
                                <a
                                    href="javascript:void(0)"
                                    class="text-dark"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="list.lokasi_penjembutan"
                                >
                                    <span class="limit-one-line">
                                        {{ list.lokasi_penjembutan }}
                                    </span>
                                </a>
                            </td>
                            <td class="td">
                                {{ list.jenis_mobil }}
                            </td>
                            <td class="td">
                                {{ currencyFormat(list.harga_mobil) }}
                            </td>
                        </tr>
                        <tr class="border-footer bc-gray pb-5" :key="index">
                            <td class="p-2 pt-1 pb-1" colspan="10">
                                <div class="d-flex align-items-center">
                                    <div class="d-flex align-items-center">
                                        <div>
                                            <i
                                                class="fa fa-calendar-o me-1"
                                            ></i>
                                            {{
                                                moment(list.created_at).format(
                                                    "DD MMMM YYYY"
                                                )
                                            }}
                                        </div>
                                    </div>
                                    <div class="ms-auto">
                                        <a
                                            href="javascript:void(0)"
                                            @click="acceptModalOpen(list)"
                                            class="btn btn-green btn-sm px-3"
                                        >
                                            Terima
                                        </a>
                                        <!-- <a href="javascript:void(0)" @click="declineModalOpen(list)" class="btn btn-outline-primary btn-sm px-3">
                                            Tolak
                                        </a> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr :key="number">
                            <td colspan="9">
                                <div class=""></div>
                            </td>
                        </tr>
                    </template>
                </table>
                <div class="text-center mt-section" v-else>
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <b-pagination-nav
                v-if="list.list_data.length > 0"
                :link-gen="linkGen"
                v-model="currentPage"
                :number-of-pages="list.total_page"
                base-url="#"
                first-number
                align="right"
            ></b-pagination-nav>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="declineModal"></div>
            <div class="modal-body-chat sm" v-if="declineModal">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            <img
                                src="../../assets/decline.png"
                                width="65"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-ekstra-bold">Tolak Pesanan?</div>
                    <div class="mt-2">
                        Anda yakin untuk menolak pesanan ini? Jika pesanan
                        ditolak maka akan terhapus secara permanent
                    </div>
                    <div class="mt-2">
                        <textarea
                            cols="30"
                            rows="4"
                            class="form-control"
                            placeholder="Masukkan alasan menolak"
                            v-model="reason_message"
                        ></textarea>
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <a
                                href="javascript:void(0)"
                                class="btn btn-outline-black w-100"
                                @click="declineModal = false"
                            >
                                Batal
                            </a>
                        </div>
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-primary w-100"
                                type="button"
                                disabled
                                v-if="is_decline"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Tolak
                            </button>
                            <a
                                href="javascript:void(0)"
                                class="btn btn-primary w-100"
                                @click="decline(declineData.id)"
                                v-else
                            >
                                Tolak
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-vue">
            <div class="overlay" v-if="acceptModal"></div>
            <div class="modal-body-chat sm" v-if="acceptModal">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold">
                            <img
                                src="../../assets/accept.png"
                                width="65"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-ekstra-bold">Terima Pesanan?</div>
                    <div class="mt-2">
                        Anda yakin untuk menerima pesanan ini?
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <a
                                href="javascript:void(0)"
                                class="btn btn-outline-black w-100"
                                @click="acceptModal = false"
                            >
                                Batal
                            </a>
                        </div>
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-green w-100"
                                type="button"
                                disabled
                                v-if="is_accept"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Terima
                            </button>
                            <a
                                href="javascript:void(0)"
                                class="btn btn-green w-100"
                                @click="accept(acceptData.id)"
                                v-else
                            >
                                Terima
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import { Tooltip } from "bootstrap";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
    components: {
        ListLoader,
    },
    name: "IncomingOrder",
    data() {
        return {
            moment: moment,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            is_list_ready: false,
            reason_message: "",
            per_page: 10,
            currentPage: 0,
            declineData: {},
            declineModal: false,
            is_decline: false,
            acceptModal: false,
            acceptData: {},
            is_accept: false,
        };
    },
    created() {
        this.getLists();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    computed: {},
    methods: {
        linkGen(pageNum) {
            return pageNum === 1 ? `?` : `?page=${pageNum}`;
        },
        currencyFormat(num) {
            return (
                "Rp. " +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        getLists() {
            this.is_list_ready = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/waiting-accept-by-partner`,
                {
                    params: {
                        per_page: this.per_page,
                        page: this.$route.query.page,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        declineModalOpen(list) {
            this.declineModal = true;
            this.declineData = list;
        },
        acceptModalOpen(list) {
            this.acceptModal = true;
            this.acceptData = list;
        },
        decline() {
            if (this.reason_message == "") {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Isi alasan anda menolak",
                });
            } else {
                var data = {
                    reason_to_cancel: this.reason_message,
                };
                this.is_decline = true;
                Api.post(
                    `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/order/cancel/${this.declineData.id}`,
                    data,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.declineModal = false;
                        this.is_decline = false;
                        this.getLists();
                        console.log(res);
                    })
                    .catch((err) => {
                        this.declineModal = false;
                        this.is_decline = false;
                        console.log(err);
                    });
            }
        },
        accept() {
            this.is_accept = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/partner/order/${this.acceptData.id}`,
                "",
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.acceptModal = false;
                    this.is_accept = false;
                    this.getLists();
                    console.log(res);
                })
                .catch((err) => {
                    this.acceptModal = false;
                    this.is_accept = false;
                    console.log(err);
                });
        },
    },
};
</script>

<style>
.scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 450px;
}
</style>
