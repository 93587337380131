import { render, staticRenderFns } from "./ManagementOrderDetail.vue?vue&type=template&id=3b51caba&scoped=true"
import script from "./ManagementOrderDetail.vue?vue&type=script&lang=js"
export * from "./ManagementOrderDetail.vue?vue&type=script&lang=js"
import style0 from "./ManagementOrderDetail.vue?vue&type=style&index=0&id=3b51caba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b51caba",
  null
  
)

export default component.exports