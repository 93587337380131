<template>
    <div class="padding-container">
        <div class="box">
            <div class="mb-3">
                <h3 class="fw-bold">Daftar Pencairan</h3>
            </div>
            <div class="row">
                <div class="col-lg-6 d-flex gap-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Cari nama agent"
                        v-model="search"
                        @input="debouncedFilterData()"
                    />
                    <button
                        class="btn d-flex btn-filter gap-2 px-4"
                        @click="toggleFilter()"
                    >
                        Filter
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M4 8L9.5 8"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M16 8H20M16 8C16 6.89543 15.1046 6 14 6C12.8954 6 12 6.89543 12 8C12 9.10457 12.8954 10 14 10C15.1046 10 16 9.10457 16 8Z"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M8 16H4M8 16C8 14.8954 8.89543 14 10 14C11.1046 14 12 14.8954 12 16C12 17.1046 11.1046 18 10 18C8.89543 18 8 17.1046 8 16Z"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M14.5 16L20 16"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="box mt-3" v-if="showFilter">
                <label class="fw-bold mb-3">Filter Data</label>
                <div class="row">
                    <div class="col-md-4 mt-2">
                        <label class="mb-3">Kota/Kab. Domisili</label>
                        <multiselect
                            v-model="regency"
                            :options="regencies"
                            label="name"
                            track-by="id"
                            placeholder="Cari kota/kab"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4 mt-2 position-relative">
                        <label class="mb-3">Periode Pencairan</label>
                        <DateMonthPicker
                            v-model="disbursementPeriode"
                            placeholder-text="Pilih periode pencairan"
                        />
                    </div>
                    <div class="col-md-4 mt-2 position-relative">
                        <label class="mb-3">Status Pencairan</label>
                        <a
                            href="javascript:void(0)"
                            class="dropdown-toggle text-gray-dashboard"
                            id="disbursementStatusDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                        >
                            <div class="radius-select d-flex">
                                <div
                                    class="d-flex align-items-center justify-content-between w-100 gap-4"
                                >
                                    <div class="scroll-select">
                                        <template
                                            v-if="!selectedDisbursementStatus"
                                        >
                                            <p class="placeholder-custom">
                                                Pilih status pencairan
                                            </p>
                                        </template>
                                        <template v-else>
                                            {{
                                                selectedDisbursementStatus.name
                                                    .split("_")
                                                    .join(" ")
                                            }}
                                        </template>
                                    </div>
                                    <div class="ms-auto arrow-custom"></div>
                                </div>
                            </div>
                        </a>
                        <ul
                            class="dropdown-menu"
                            aria-labelledby="disbursementStatusDropdown"
                        >
                            <li
                                v-for="statusOption in statuses"
                                :key="statusOption.id"
                                class="pt-1 pb-1"
                            >
                                <div
                                    class="form-check form-check-inline cursor-pointer"
                                >
                                    <input
                                        class="form-check-input square-radio"
                                        type="radio"
                                        :id="statusOption.id"
                                        :value="statusOption"
                                        v-model="selectedDisbursementStatus"
                                        name="disbursementStatus"
                                    />
                                    <label
                                        class="form-check-label"
                                        :for="statusOption.id"
                                    >
                                        {{
                                            statusOption.name
                                                .split("_")
                                                .join(" ")
                                        }}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-4 mt-2 position-relative">
                        <label class="mb-3">Tanggal Pencairan</label>
                        <DateRangePicker
                            v-model="disbursementDate"
                            placeholder-text="Pilih tanggal pencairan"
                            :disabled="isDateRangeDisabled"
                        />
                    </div>
                    <div class="col-md-2 mt-2">
                        <label class="mb-3 invisible">Invisible</label>
                        <button
                            :class="[
                                'btn',
                                'px-5',
                                'w-100',
                                isDisabled
                                    ? 'btn-reset-disabled'
                                    : 'btn-reset-enabled',
                            ]"
                            :disabled="isDisabled"
                            @click="clearFilter()"
                        >
                            Reset
                        </button>
                    </div>
                    <div class="col-md-2 mt-2">
                        <label class="mb-3 invisible">Invisible</label>
                        <button
                            :class="[
                                'btn',
                                'px-5',
                                'w-100',
                                isDisabled
                                    ? 'btn-filter-disabled'
                                    : 'btn-green',
                            ]"
                            @click="applyFilter()"
                            :disabled="isDisabled"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="isLoading">
                <ListLoader />
            </div>
            <div v-else>
                <div
                    class="d-flex justify-content-between align-items-center mt-4"
                >
                    <p class="mb-0">
                        Menampilkan
                        <span class="fw-bold"
                            >{{ pagination.start }}-{{ pagination.end }} </span
                        >Data dari
                        <span class="fw-bold">{{ totalData }} </span>Data
                    </p>
                    <div class="d-flex gap-3 align-items-center">
                        <p class="mb-0">Rows Per Page</p>
                        <select
                            class="form-control form-select w-select bg-row"
                            v-model="rowsPerPage"
                            @change="changeRowsPerPage()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nama Agent
                                </th>
                                <th class="text-center" scope="col">
                                    Kota/Kabupaten
                                </th>
                                <th class="text-center" scope="col">
                                    Nominal Pencairan
                                </th>
                                <th class="text-center" scope="col">Periode</th>
                                <th class="text-center" scope="col">
                                    Status Pencairan
                                </th>
                                <th class="text-center" scope="col">
                                    Tanggal Pencairan
                                </th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(disbursement, index) in disbursements"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="text-center">
                                    {{ disbursement.agent_name || "-" }}
                                </td>
                                <td class="text-center">
                                    {{ disbursement.regency || "-" }}
                                </td>
                                <td>
                                    {{ currency(disbursement.amount) || "-" }}
                                </td>
                                <td>{{ disbursement.period || "-" }}</td>
                                <td class="text-center d-flex flex-column">
                                    <div
                                        v-if="disbursement.status === 'pending'"
                                        class="tag rounded-pill w-100 capitalize badge-pending"
                                    >
                                        Pending
                                    </div>
                                    <div
                                        v-else-if="
                                            disbursement.status === 'transfer'
                                        "
                                        class="tag rounded-pill w-100 capitalize badge-transferred"
                                    >
                                        Transferred
                                    </div>
                                    <p v-else>-</p>
                                </td>
                                <td class="text-center">
                                    {{ disbursement.transfer_at || "-" }}
                                </td>
                                <td class="text-center">
                                    <router-link
                                        v-if="disbursement.status === 'pending'"
                                        :to="`/disbursement-sales-affiliate/${disbursement.user_id}/${disbursement.raw_period}`"
                                        class="btn btn-transfer d-flex align-items-center gap-2 justify-content-center"
                                    >
                                        Transfer Komisi
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                        >
                                            <path
                                                d="M8.29916 2.27588H2.84199C2.57569 2.27588 2.34315 2.18024 2.14437 1.98896C1.94634 1.79768 1.84732 1.56514 1.84732 1.29134C1.84732 1.01755 1.94634 0.781257 2.14437 0.582474C2.34241 0.38369 2.57494 0.284673 2.84199 0.285423H10.805C11.063 0.285423 11.279 0.372812 11.4531 0.547592C11.6271 0.721622 11.7141 0.937283 11.7141 1.19458V9.15754C11.7141 9.42459 11.6185 9.65713 11.4272 9.85516C11.2352 10.0539 11.0026 10.1533 10.7296 10.1533C10.4558 10.1533 10.2195 10.0539 10.0207 9.85516C9.82267 9.65713 9.72365 9.42459 9.72365 9.15754V3.72625L2.0116 11.4383C1.81507 11.6311 1.57503 11.7275 1.29148 11.7275C1.00793 11.7275 0.769014 11.63 0.574732 11.4349C0.380449 11.2399 0.284057 11.0002 0.285557 10.7159C0.287057 10.4316 0.38345 10.1935 0.574732 10.0014L8.29916 2.27588Z"
                                                fill="#FBFBFB"
                                            />
                                        </svg>
                                    </router-link>
                                    <router-link
                                        v-else-if="
                                            disbursement.status === 'transfer'
                                        "
                                        :to="`/disbursement-sales-affiliate/${disbursement.user_id}/${disbursement.raw_period}`"
                                        class="btn btn-detail d-flex align-items-center gap-2 justify-content-center"
                                    >
                                        Lihat Detail
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="12"
                                            viewBox="0 0 14 12"
                                            fill="none"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M6.9987 10.6666C9.05977 10.6666 10.9741 9.4483 12.2905 7.35024C12.8078 6.52581 12.8078 5.47415 12.2905 4.64972C10.9741 2.55166 9.05977 1.33331 6.9987 1.33331H7.0013C4.94023 1.33331 3.02594 2.55166 1.70952 4.64972C1.19223 5.47415 1.19223 6.52581 1.70952 7.35024C3.02594 9.4483 4.94023 10.6666 7.0013 10.6666H6.9987Z"
                                                fill="#FBFBFB"
                                                stroke="#FBFBFB"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M7 4C8.10457 4 9 4.89543 9 6C9 7.10457 8.10457 8 7 8C5.89543 8 5 7.10457 5 6C5 4.89543 5.89543 4 7 4Z"
                                                fill="#FBFBFB"
                                                stroke="#48A2D4"
                                                stroke-width="1.5"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </router-link>
                                    <p v-else>-</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    class="text-center mt-section"
                    v-if="disbursements.length === 0"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="d-flex">
            <div class="ms-auto">
                <div class="d-flex mt-4">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalData"
                        :per-page="rowsPerPage"
                        align="right"
                        @change="changePage"
                    ></b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from "lodash";
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import Multiselect from "vue-multiselect";
import DateRangePicker from "../../components/DateRangePicker.vue";
import DateMonthPicker from "../../components/DateMonthPicker.vue";
import moment from "moment";

export default {
    components: {
        Multiselect,
        ListLoader,
        DateRangePicker,
        DateMonthPicker,
    },
    data() {
        return {
            isLoading: false,
            showFilter: false,
            disbursementPeriode: {
                month: null,
                year: null,
            },
            disbursementDate: {
                from: null,
                to: null,
            },
            disbursementStatus: null,
            search: null,
            regency: null,
            regencies: [],
            selectedDisbursementStatus: null,
            statuses: [
                {
                    name: "Pending",
                    id: "pending",
                },
                {
                    name: "Transferred",
                    id: "transfer",
                },
            ],
            disbursements: [],
            totalData: 0,
            rowsPerPage: 10,
            currentPage: 1,
            moment: moment,
        };
    },
    created() {
        this.getRegency();
        this.fetchData();
        this.debouncedFilterData = debounce(this.filterData, 1000);
    },
    computed: {
        isDateRangeDisabled() {
            return (
                this.selectedDisbursementStatus?.id === "pending" ||
                this.selectedDisbursementStatus === null
            );
        },
        isDisabled() {
            return (
                !this.regency ||
                !this.selectedDisbursementStatus ||
                !this.disbursementPeriode.month ||
                !this.disbursementPeriode.year
            );
        },
        pagination() {
            const start = (this.currentPage - 1) * this.rowsPerPage + 1;
            const end = Math.min(
                this.currentPage * this.rowsPerPage,
                this.totalData
            );
            return { start, end };
        },
    },
    watch: {
        selectedDisbursementStatus(newValue) {
            if (newValue && newValue.id === "pending") {
                this.disbursementDate = {
                    from: null,
                    to: null,
                };
            }
        },
    },
    methods: {
        filterData() {
            this.currentPage = 1;
            this.fetchData();
        },
        applyFilter() {
            this.currentPage = 1;
            this.fetchData();
        },
        changeRowsPerPage() {
            this.currentPage = 1;
            this.fetchData();
        },
        changePage(page) {
            this.currentPage = page;
            this.fetchData();
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        fetchData() {
            this.isLoading = true;

            const params = {
                limit: this.rowsPerPage || null,
                status: this.selectedDisbursementStatus?.id || null,
                period:
                    this.disbursementPeriode.formattedMonth &&
                    this.disbursementPeriode.year
                        ? `${this.disbursementPeriode.year}-${this.disbursementPeriode.formattedMonth}`
                        : null,
                transfer_at:
                    this.disbursementDate.from && this.disbursementDate.to
                        ? `${moment(this.disbursementDate.from).format(
                              "DD/MM/YYYY"
                          )}-${moment(this.disbursementDate.to).format(
                              "DD/MM/YYYY"
                          )}`
                        : null,
                search: this.search || null,
                regency: this.regency?.name || null,
                page: this.currentPage || null,
            };

            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/sa/admin/disbursement`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                    params,
                }
            )
                .then((response) => {
                    const data = response.data.data;
                    this.disbursements = data.list_data;
                    this.totalData = data.total_data;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        toggleFilter() {
            this.showFilter = !this.showFilter;
        },
        clearFilter() {
            this.selectedDisbursementStatus = null;
            this.regency = null;
            this.disbursementPeriode = {
                month: null,
                year: null,
            };
            this.disbursementDate = {
                from: null,
                to: null,
            };
            this.disbursementStatus = null;
            this.fetchData();
        },
    },
};
</script>

<style scoped>
.form-check-input.square-radio {
    border-radius: 0 !important;
    width: 16px;
    height: 16px;
    margin-top: 0.2em;
}
.form-check-input.square-radio:checked {
    background-color: #3d8824;
    border-color: #3d8824;
    border-radius: 0 !important;
}
.form-check-input.square-radio:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8l2 2l6-6'/%3e%3c/svg%3e");
    background-size: 75%;
}
.form-check-input.square-radio:hover {
    cursor: pointer;
    border-color: #3d8824;
}
.form-check-input.square-radio:focus {
    border-color: #3d8824;
    box-shadow: 0 0 0 0.25rem rgba(50, 154, 95, 0.25);
}
.dropdown-menu.show {
    width: 93%;
}
.dropdown-toggle::after {
    display: none;
}
.arrow-custom::before {
    position: relative;
    right: 8px;
    top: 12px;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: "";
}
div::-webkit-scrollbar {
    display: none;
}
.radius-select {
    border: 1px solid #e9ecef !important;
    padding: 0px 7px 0 7px !important;
    min-height: 35px !important;
    border-radius: 5px;
}
.scroll-select {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.scroll-select .placeholder-custom {
    margin-bottom: 0;
    color: #6c757d;
    font-size: 13px;
}
.dropdown-menu {
    padding: 5px 10px 0px 10px;
}
.form-check-input:checked {
    background-color: #3d8824;
    border: #3d8824;
}

th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.btn-filter {
    border: 1px solid #d9d9d9;
}
.btn-filter:hover {
    border: 1px solid #d9d9d9;
}
.badge-pending {
    background-color: #feead4;
    color: #d17e20;
}
.badge-transferred {
    background-color: #d8e7d3;
    color: #33711e;
}
.btn-transfer {
    background-color: #3d8824;
    color: #fbfbfb;
}
.btn-detail {
    background-color: #48a2d4;
    color: #fbfbfb;
}
.btn-transfer:hover {
    background-color: #3d8824;
    color: #fbfbfb;
}
.btn-detail:hover {
    background-color: #48a2d4;
    color: #fbfbfb;
}
.btn-transfer:active {
    background-color: #3d8824 !important;
    color: #fbfbfb !important;
}
.btn-detail:active {
    background-color: #48a2d4 !important;
    color: #fbfbfb !important;
}
.btn-reset-disabled {
    color: #d2d2d5 !important;
    border: 1px solid #d2d2d5 !important;
}
.btn-filter-disabled {
    background-color: #d2d2d5 !important;
    border: none !important;
    color: #ffffff !important;
}
.btn-reset-enabled {
    border: 1px solid #fb9727;
    color: #fb9727;
}
.btn-reset-enabled:hover {
    border: 1px solid #fb9727;
    color: #fb9727;
}
</style>
