<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a
                href="/trawlcarrier/ho/order"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h4 class="fw-bold mb-0">Assign Mitra</h4>
                <div class="ms-auto">
                    <button
                        class="btn btn-primary px-5"
                        :disabled="detail.status == 'cancel'"
                        @click="modalCancel = true"
                    >
                        Batalkan Pesanan
                    </button>
                </div>
            </div>
            <div class="box no-shadow border-gray mt-4">
                <div class="row">
                    <div class="col-md-2 fw-bold">Kode Resi</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        <strong>{{ detail.receipt }}</strong>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Nama Akun</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ detail.customer.name }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Nama Pengirim</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-8">
                        <input
                            type="text"
                            ref="receiver_name"
                            v-model="detail.origin.name"
                            :disabled="!is_edit_sender_name"
                            v-bind:class="
                                !is_edit_sender_name
                                    ? 'view'
                                    : 'form-edit-custom m-mt-0-5'
                            "
                        />
                    </div>
                    <div class="col-md-1 mt-1">
                        <a
                            href="javascript:void(0)"
                            class="text-blue-edit"
                            @click="is_edit_sender_name = !is_edit_sender_name"
                            v-if="!is_edit_sender_name"
                        >
                            <i class="fa fa-pencil me-2"></i>
                            Edit
                        </a>
                        <a
                            href="javascript:void(0)"
                            class="text-green"
                            v-if="is_edit_sender_name"
                            @click="saveReceiver()"
                        >
                            <i class="fa fa-save me-2"></i>
                            Save
                        </a>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Kontak Pengirim</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-8">
                        <input
                            type="number"
                            ref="receiver_name"
                            v-model="detail.origin.phone"
                            :disabled="!is_edit_sender_phone"
                            v-bind:class="
                                !is_edit_sender_phone
                                    ? 'view'
                                    : 'form-edit-custom m-mt-0-5 number'
                            "
                        />
                    </div>
                    <div class="col-md-1 mt-1">
                        <a
                            href="javascript:void(0)"
                            class="text-blue-edit"
                            @click="
                                is_edit_sender_phone = !is_edit_sender_phone
                            "
                            v-if="!is_edit_sender_phone"
                        >
                            <i class="fa fa-pencil me-2"></i>
                            Edit
                        </a>
                        <a
                            href="javascript:void(0)"
                            class="text-green"
                            v-if="is_edit_sender_phone"
                            @click="saveReceiver()"
                        >
                            <i class="fa fa-save me-2"></i>
                            Save
                        </a>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Lokasi Pengirim</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ detail.origin.address }}
                    </div>
                </div>
            </div>
            <div class="box no-shadow border-gray mt-4">
                <div class="row">
                    <div class="col-md-2 fw-bold">Nama Penerima</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-8">
                        <input
                            type="text"
                            ref="receiver_name"
                            v-model="detail.destination.name"
                            :disabled="!is_edit_receiver_name"
                            v-bind:class="
                                !is_edit_receiver_name
                                    ? 'view'
                                    : 'form-edit-custom m-mt-0-5'
                            "
                        />
                    </div>
                    <div class="col-md-1 mt-1">
                        <a
                            href="javascript:void(0)"
                            class="text-blue-edit"
                            @click="
                                is_edit_receiver_name = !is_edit_receiver_name
                            "
                            v-if="!is_edit_receiver_name"
                        >
                            <i class="fa fa-pencil me-2"></i>
                            Edit
                        </a>
                        <a
                            href="javascript:void(0)"
                            class="text-green"
                            v-if="is_edit_receiver_name"
                            @click="saveReceiver()"
                        >
                            <i class="fa fa-save me-2"></i>
                            Save
                        </a>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Kontak Penerima</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-8">
                        <input
                            type="number"
                            ref="receiver_name"
                            v-model="detail.destination.phone"
                            :disabled="!is_edit_receiver_phone"
                            v-bind:class="
                                !is_edit_receiver_phone
                                    ? 'view'
                                    : 'form-edit-custom m-mt-0-5 number'
                            "
                        />
                    </div>
                    <div class="col-md-1 mt-1">
                        <a
                            href="javascript:void(0)"
                            class="text-blue-edit"
                            @click="
                                is_edit_receiver_phone = !is_edit_receiver_phone
                            "
                            v-if="!is_edit_receiver_phone"
                        >
                            <i class="fa fa-pencil me-2"></i>
                            Edit
                        </a>
                        <a
                            href="javascript:void(0)"
                            class="text-green"
                            v-if="is_edit_receiver_phone"
                            @click="saveReceiver()"
                        >
                            <i class="fa fa-save me-2"></i>
                            Save
                        </a>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Lokasi Penerima</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ detail.destination.address }}
                    </div>
                </div>
            </div>
            <div class="box no-shadow border-gray mt-4">
                <div class="row">
                    <div class="col-md-2 fw-bold">Tanggal Order</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{
                            moment(detail.created_at).format(
                                "DD MMMM YYYY HH:mm"
                            )
                        }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Tanggal Pickup</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-8">
                        <date-picker
                            v-model="pickup_at"
                            type="datetime"
                            :disabled="!is_edit_pickup_date"
                            value-type="format"
                            format="DD MMMM YYYY HH:mm"
                            v-bind:class="
                                !is_edit_pickup_date ? 'view' : 'm-mt-0-5'
                            "
                            :clearable="false"
                            placeholder="Masukkan tanggal"
                            style="width: 200px"
                        >
                        </date-picker>
                    </div>
                    <div class="col-md-1 mt-1">
                        <a
                            href="javascript:void(0)"
                            class="text-blue-edit"
                            @click="is_edit_pickup_date = !is_edit_pickup_date"
                            v-if="!is_edit_pickup_date"
                        >
                            <i class="fa fa-pencil me-2"></i>
                            Edit
                        </a>
                        <a
                            href="javascript:void(0)"
                            class="text-green"
                            v-if="is_edit_pickup_date"
                            @click="saveReceiver()"
                        >
                            <i class="fa fa-save me-2"></i>
                            Save
                        </a>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Kategori Kendaraan</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ detail.meta.class }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Brand Kendaraan</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ detail.meta.brand }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Tipe Kendaraan</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ detail.meta.variant }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Nomor Polisi</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ detail.meta.plate_number }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">
                        Perkiraan Harga Kendaraan
                    </div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ currencyFormat(detail.meta.price) }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Asuransi</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ currencyFormat(detail.meta.insurance) }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Foto Kendaraan</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        <div class="row">
                            <template v-if="detail.meta.images.length > 0">
                                <template
                                    v-for="(image, index) in detail.meta.images"
                                >
                                    <div
                                        class="col-6 col-md-2 mt-3"
                                        :key="index"
                                    >
                                        <viewer
                                            :images="detail.meta.images"
                                            :options="options"
                                        >
                                            <img
                                                class="driver-item cursor-pointer"
                                                :src="image.image"
                                                :alt="image.image"
                                            />
                                        </viewer>
                                    </div>
                                </template>
                            </template>
                            <template>-</template>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Foto STNK</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-6 col-md-2">
                                <template
                                    v-if="
                                        (detail.meta &&
                                            detail.meta.stnk != null) ||
                                        detail.meta.stnk != ''
                                    "
                                >
                                    <viewer
                                        :images="[detail.meta.stnk]"
                                        :options="options"
                                    >
                                        <img
                                            :src="detail.meta.stnk"
                                            :alt="detail.meta.stnk"
                                            class="driver-item cursor-pointer"
                                        />
                                    </viewer>
                                </template>
                                <template v-else> - </template>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Keterangan Tambahan</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        <template v-if="detail.meta.note">
                            {{ detail.meta.note }}
                        </template>
                        <template v-else>-</template>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Jenis Layanan</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9 capitalize fw-bold">
                        {{
                            (detail.service_type || "").split("_").join(" ") +
                            (detail.service_option &&
                            detail.service_option != detail.service_type
                                ? " [ " +
                                  detail.service_option.split("_").join(" ") +
                                  " ]"
                                : "")
                        }}
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center mt-4">
                <h4 class="fw-bold mb-0">Rincian Harga</h4>
            </div>
            <div class="box no-shadow border-gray mt-4">
                <div class="row">
                    <div class="col-md-2 fw-bold">Harga Layanan</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ currencyFormat(detail.delivery_price) }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Asuransi</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ currencyFormat(detail.insurance_price) }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Biaya Jasa Aplikasi</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ currencyFormat(detail.platform_fee) }}
                    </div>
                </div>
                <hr />
                <div class="row" v-if="detail.payment">
                    <div class="col-md-2 fw-bold">Biaya VA</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{
                            currencyFormat(detail.payment.payment_admin_charge)
                        }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Total Biaya</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ currencyFormat(getTotalPrice()) }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Status Pembayaran</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ getPaymentStatus() }}
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center mt-4">
                <h4 class="fw-bold mb-0">Menugaskan Mitra</h4>
            </div>
            <div class="box no-shadow border-gray mt-4">
                <div>
                    <label class="fw-bold mb-2">Mitra</label>
                    <select
                        class="form-control form-select"
                        v-model="req.mitra"
                        @change="getMitra()"
                    >
                        <option value="">Pilih mitra</option>
                        <template v-for="(mitra, index) in mitra.list_data">
                            <option :value="mitra.id" :key="index">
                                {{ mitra.nama_mitra }}
                            </option>
                        </template>
                    </select>
                </div>
            </div>
            <div class="box no-shadow border-gray mt-4" v-if="req.mitra">
                <div class="row">
                    <div class="col-md-2 fw-bold">Kode Mitra</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ mitra_data.nama_mitra }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Nama Perusahaan</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ mitra_data.pic }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Nomor Telepon</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ mitra_data.kontak_mitra_phone }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Email</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ mitra_data.kontak_mitra_email }}
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-md-2 fw-bold">Alamat Mitra</div>
                    <div class="col-md-1 d-none d-lg-block">:</div>
                    <div class="col-md-9">
                        {{ mitra_data.lokasi_mitra }}
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-8"></div>
                <div class="col-md-4">
                    <button
                        class="btn btn-green btn-lg w-100"
                        type="button"
                        disabled
                        v-if="is_store"
                    >
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        Tugaskan Mitra
                    </button>
                    <button
                        class="btn btn-green btn-lg w-100"
                        @click="assignMitra()"
                        :disabled="
                            req.mitra == '' || req.mitra == detail?.partner?.id
                        "
                        v-else
                    >
                        Tugaskan Mitra
                    </button>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalCancel"></div>
            <div class="modal-body-chat vsm" v-if="modalCancel">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/close.png"
                        width="80"
                        alt=""
                    />
                    <div>
                        <h4 class="mb-0 fw-bold mt-4">
                            Apakah Anda yakin membatalkan pesanan ini?
                        </h4>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-md-6">
                        <a
                            href="javascript:void(0)"
                            @click="modalCancel = false"
                            class="btn btn-outline-red w-100 btn-lg"
                            >Batal</a
                        >
                    </div>
                    <div class="col-md-6 m-mt-1">
                        <button
                            class="btn btn-primary w-100 btn-lg"
                            type="button"
                            disabled
                            v-if="is_cancel"
                        >
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Ya
                        </button>
                        <button
                            class="btn btn-primary w-100 btn-lg"
                            @click="cancelOrder()"
                            v-else
                        >
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import $ from "jquery";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";

export default {
    components: {
        DatePicker,
        Viewer,
    },
    name: "TrawlcareerPayment",
    data() {
        return {
            moment: moment,
            detail: {},
            mitra: {
                list_data: [],
            },
            req: {
                mitra: "",
            },
            is_store: false,
            mitra_data: {
                id: "",
                kontak_mitra_email: "",
                kontak_mitra_phone: "",
                logo: "",
                lokasi_mitra: "",
                nama_mitra: "",
                pic: "",
            },
            is_edit_receiver_name: false,
            is_edit_receiver_phone: false,
            is_edit_sender_name: false,
            is_edit_sender_phone: false,
            is_edit_pickup_date: false,
            pickup_at: "",
            modalCancel: false,
            is_cancel: false,
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
        };
    },
    created() {
        this.getDetails();
        this.getMitra();
    },
    mounted() {
        $(".number").keypress(function (e) {
            if (
                e.which != 8 &&
                e.which != 0 &&
                (e.which < 48 || e.which > 57)
            ) {
                $("#errmsg").html("Number Only").stop().show().fadeOut("slow");
                return false;
            }
        });
    },
    computed: {},
    methods: {
        getDetails() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/order/detail/${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    console.log(data);
                    this.detail = data;
                    if (data.partner) {
                        this.req.mitra = data.partner.id;
                    } else {
                        this.req.mitra = "";
                    }
                    this.pickup_at = moment(data.pickup_at).format(
                        "DD MMMM YYYY HH:mm"
                    );
                    this.getMitra();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getMitra() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/partner?per_page=50`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.mitra = data;
                    this.mitra.list_data.forEach((val) => {
                        if (val.id == this.req.mitra) {
                            this.mitra_data = val;
                        }
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        currencyFormat(num) {
            return (
                "Rp" +
                parseInt(num)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
            );
        },
        assignMitra() {
            this.is_store = true;
            var data = {
                partner_id: this.req.mitra,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/order/assign-partner/${this.$route.params.id}`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Mitra berhasil ditugaskan",
                    });
                    this.is_store = false;
                    setTimeout(() => {
                        window.location.href = "/trawlcarrier/ho/order";
                    }, 1000);
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                    this.is_store = false;
                });
        },
        saveReceiver() {
            var data = {
                order_id: this.detail.id,
                sender_name: this.detail.origin.name,
                sender_phone: this.detail.origin.phone,
                receiver_name: this.detail.destination.name,
                receiver_phone: this.detail.destination.phone,
                pickup_at: moment(this.pickup_at).format("YYYY-MM-DD HH:mm:ss"),
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/order/update`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                        Accept: "application/json",
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_edit_receiver_name = false;
                    this.is_edit_receiver_phone = false;
                    this.is_edit_sender_name = false;
                    (this.is_edit_sender_phone = false),
                        (this.is_edit_pickup_date = false),
                        this.$notify({
                            group: "foo",
                            type: "success",
                            title: "Success",
                            text: "Data Berhasil Diubah",
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        cancelOrder() {
            this.is_cancel = true;
            var data = {
                order_id: this.detail.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/carrier/ho/order/cancel`,
                data,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Pesanan Berhasil Dibatalkan",
                    });
                    this.is_cancel = false;
                    setTimeout(() => {
                        window.location.href = "/trawlcarrier/ho/order";
                    }, 1000);
                    console.log(res);
                    this.modalCancel = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_cancel = false;
                    this.modalCancel = false;
                });
        },
        getTotalPrice() {
            return (
                this.detail.payment?.total_payment ?? this.detail.total_price
            );
        },
        getPaymentStatus() {
            const paymentStatus = this.detail.payment?.status;

            switch (paymentStatus) {
                case "cancel":
                    return "Dibatalkan";
                case "success":
                    return "Lunas";
                case "pending":
                    return "Menunggu pembayaran";
                default:
                    return paymentStatus
                        ? "Status tidak dikenal"
                        : "Belum generate VA";
            }
        },
    },
};
</script>

<style>
.driver-item {
    width: 100%;
    height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    border: 2px solid #e2e3e5;
}
.view {
    border-color: transparent;
    background-color: initial;
    color: initial;
    padding: 0px;
}
.view .mx-input {
    border: unset !important;
    padding: 0 !important;
    color: #000;
}
.view .mx-input:disabled {
    background: #fff !important;
    cursor: default;
    padding: 0 !important;
    height: unset !important;
}
.view svg {
    display: none !important;
}
.form-edit-custom {
    border: 1px solid #e9ecef;
    border-radius: 5px;
    font-size: 14px;
    display: block;
    width: 100%;
    padding: 0.3rem 0.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    margin-top: -1.5px;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-edit-custom:focus-visible {
    outline: unset;
}
</style>
