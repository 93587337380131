<template>
    <div class="padding-container">
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">Deal Order List</h3>
            </div>
            <div class="row mt-4 m-mt-1">
                <div class="col-md-5">
                    <div class="d-flex align-items-center">
                        <div class="search-form">
                            <input
                                type="text"
                                class="form-control w-search"
                                @keyup.enter="changeSearch"
                                v-model="req.order_search"
                                placeholder="Search Resi"
                            />
                            <button
                                class="btn btn-primary"
                                @click="changeSearch()"
                            >
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                        <div class="ms-4">
                            <a
                                href="javascript:void(0)"
                                class="dropdown-toggle text-black"
                                id="statusRefund"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="true"
                                aria-expanded="false"
                            >
                                <div class="size-12 text-gray">
                                    Filter Service
                                    <i class="fa fa-sort ms-1"></i>
                                </div>
                                <div class="fw-semibold capitalize">
                                    <template v-if="filter_service">
                                        {{ filter_service }}
                                    </template>
                                    <template v-else> Select Service </template>
                                </div>
                            </a>
                            <ul
                                class="dropdown-menu mt-2"
                                aria-labelledby="statusRefund"
                            >
                                <li>
                                    <a
                                        class="dropdown-item"
                                        href="javascript:void(0)"
                                        @click="getFilterService('trawlpack')"
                                    >
                                        Troben Cargo
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="dropdown-item"
                                        href="javascript:void(0)"
                                        @click="getFilterService('trawltruck')"
                                    >
                                        Troben Truck
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="dropdown-item"
                                        href="javascript:void(0)"
                                        @click="
                                            getFilterService('trawlcarrier')
                                        "
                                    >
                                        Troben Carier
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="dropdown-item no-hover"
                                        href="javascript:void(0)"
                                        @click="getFilterService('')"
                                    >
                                        <button
                                            class="btn btn-outline-primary btn-sm w-100"
                                        >
                                            Clear Filter
                                        </button>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="ms-4">
                            <a
                                href="javascript:void(0)"
                                class="dropdown-toggle text-black"
                                id="statusRefund"
                                data-bs-toggle="dropdown"
                                data-bs-auto-close="true"
                                aria-expanded="false"
                            >
                                <div class="size-12 text-gray">
                                    Filter Category
                                    <i class="fa fa-sort ms-1"></i>
                                </div>
                                <div class="fw-semibold capitalize">
                                    <template v-if="filter_category">
                                        {{ filter_category }}
                                    </template>
                                    <template v-else>
                                        Select Category
                                    </template>
                                </div>
                            </a>
                            <ul
                                class="dropdown-menu mt-2"
                                aria-labelledby="statusRefund"
                            >
                                <li>
                                    <a
                                        class="dropdown-item"
                                        href="javascript:void(0)"
                                        @click="getFilterCategory('repeat')"
                                    >
                                        Repeat Order
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="dropdown-item"
                                        href="javascript:void(0)"
                                        @click="getFilterCategory('new')"
                                    >
                                        New Order
                                    </a>
                                </li>
                                <li>
                                    <a
                                        class="dropdown-item no-hover"
                                        href="javascript:void(0)"
                                        @click="getFilterCategory('')"
                                    >
                                        <button
                                            class="btn btn-outline-primary btn-sm w-100"
                                        >
                                            Clear Filter
                                        </button>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-7 m-mt-2">
                    <div class="fw-bold pull-right size-24 m-size-18">
                        Total Income
                        <span class="text-green size-24 m-size-18">{{
                            currency(order.total)
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load_order">
                <ListLoader />
            </div>
            <template v-else>
                <template v-if="order.list_data.length > 0">
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Date
                                    </th>
                                    <th class="text-center" scope="col">
                                        Service
                                    </th>
                                    <th class="text-center" scope="col">
                                        Resi
                                    </th>
                                    <th class="text-center" scope="col">
                                        Category
                                    </th>
                                    <th class="text-center" scope="col">
                                        Cust Name
                                    </th>
                                    <th class="text-center" scope="col">
                                        Sender Name
                                    </th>
                                    <th class="text-center" scope="col">
                                        Owner
                                    </th>
                                    <th class="text-center" scope="col">
                                        Price
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template
                                    v-for="(order, index) in order.list_data"
                                >
                                    <tr :key="index">
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="text-center">
                                            {{
                                                moment(order.date).format(
                                                    "DD/MM/YYYY"
                                                )
                                            }}
                                        </td>
                                        <td class="text-center capitalize">
                                            <small
                                                class="tag red-solid w-100 radius"
                                                v-bind:class="{
                                                    'red-solid':
                                                        order.service ==
                                                        'trawlpack',
                                                    'blue-solid':
                                                        order.service ==
                                                        'trawltruck',
                                                    'yellow-solid':
                                                        order.service ==
                                                        'trawlcarrier',
                                                }"
                                            >
                                                <template
                                                    v-if="
                                                        order.service ==
                                                        'trawlpack'
                                                    "
                                                >
                                                    Troben Cargo
                                                </template>
                                                <template
                                                    v-if="
                                                        order.service ==
                                                        'trawltruck'
                                                    "
                                                >
                                                    Troben Truck
                                                </template>
                                                <template
                                                    v-if="
                                                        order.service ==
                                                        'trawlcarrier'
                                                    "
                                                >
                                                    Troben Carier
                                                </template>
                                            </small>
                                        </td>
                                        <td class="text-center">
                                            <a
                                                :href="
                                                    `/crm/order/detail?id=` +
                                                    order.id +
                                                    `&service=` +
                                                    order.service
                                                "
                                                class="text-link"
                                            >
                                                {{
                                                    order.receipt_code
                                                        ? order.receipt_code
                                                        : "-"
                                                }}
                                            </a>
                                        </td>
                                        <td class="text-center">
                                            <template
                                                v-if="
                                                    order.category == 'repeat'
                                                "
                                            >
                                                Repeat Order
                                            </template>
                                            <template v-else>
                                                New Order
                                            </template>
                                        </td>
                                        <td>
                                            {{
                                                order.customer_name
                                                    ? order.customer_name
                                                    : "-"
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                order.sender_name
                                                    ? order.sender_name
                                                    : "-"
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                order.crm_specialist_name
                                                    ? order.crm_specialist_name
                                                    : "-"
                                            }}
                                        </td>
                                        <td>
                                            {{ currency(order.amount) }}
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center mt-section">
                        <img
                            src="../../assets/no-data.png"
                            width="100"
                            alt=""
                        />
                        <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                            Data Kosong
                        </h4>
                    </div>
                </template>
            </template>
            <div class="d-flex" v-if="order.list_data.length > 0">
                <div class="ms-auto">
                    <div class="d-flex mt-4">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="per_page_order"
                                @change="getOrderList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <b-pagination-nav
                            :link-gen="linkGenOrder"
                            v-model="currentPageOrder"
                            :number-of-pages="order.total_page"
                            base-url="#"
                            first-number
                            align="right"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";

export default {
    components: {
        ListLoader,
    },
    name: "CrmOrder",
    data() {
        return {
            moment: moment,
            is_load_order: false,
            order: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page_order: 10,
            currentPageOrder: 0,
            req: {
                order_search: "",
            },
            filter_service: "",
            filter_category: "",
        };
    },
    created() {},
    mounted() {
        this.getOrderList();
    },
    methods: {
        changeSearch() {
            this.currentPageOrder = 0;
            this.getOrderList();
        },
        linkGenOrder(pageNumOrder) {
            return pageNumOrder === 1 ? `?` : `?page=${pageNumOrder}`;
        },
        getOrderList() {
            this.is_load_order = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/crm/orders`, {
                params: {
                    search: this.req.order_search,
                    service: this.filter_service,
                    category: this.filter_category,
                    per_page: this.per_page_order,
                    page: this.$route.query.page,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.order = data;
                    this.is_load_order = false;
                })
                .catch((err) => {
                    this.is_load_order = false;
                    console.log(err);
                });
        },
        getFilterService(value) {
            this.filter_service = value;
            this.getOrderList();
        },
        getFilterCategory(value) {
            this.filter_category = value;
            this.getOrderList();
        },
    },
};
</script>

<style scoped>
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
.nav-link.dashboard.active {
    background-color: #e60013 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.text-red-dashboard {
    color: #e60013;
}
.dropdown-toggle::after {
    display: none;
}
.dropdown-menu.show {
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border: 1px solid #f7f7f7;
    border-radius: 8px;
}
.dropdown-item {
    font-weight: 500 !important;
}
.dropdown-item.no-hover:hover {
    background-color: #fff !important;
}
.w-search {
    width: 200px !important;
}
</style>
