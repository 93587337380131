<template>
    <div class="padding-container">
        <div class="box">
            <div class="mb-3">
                <h3 class="fw-bold">Daftar Agent Training</h3>
            </div>
            <div class="row">
                <div class="col-lg-6 d-flex gap-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Cari nama agent"
                        v-model="search"
                        @input="debouncedFilterData"
                    />
                    <button
                        class="btn d-flex btn-filter gap-2 px-4"
                        @click="toggleFilter()"
                    >
                        Filter
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                d="M4 8L9.5 8"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M16 8H20M16 8C16 6.89543 15.1046 6 14 6C12.8954 6 12 6.89543 12 8C12 9.10457 12.8954 10 14 10C15.1046 10 16 9.10457 16 8Z"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M8 16H4M8 16C8 14.8954 8.89543 14 10 14C11.1046 14 12 14.8954 12 16C12 17.1046 11.1046 18 10 18C8.89543 18 8 17.1046 8 16Z"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M14.5 16L20 16"
                                stroke="#61616A"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="box mt-3" v-if="showFilter">
                <label class="fw-bold mb-3">Filter Data</label>
                <div class="row">
                    <div class="col-md-4 mt-2">
                        <label class="mb-3">Kota/Kab. Domisili</label>
                        <multiselect
                            v-model="regency"
                            :options="regencies"
                            label="name"
                            track-by="id"
                            placeholder="Cari kota/kab"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4 mt-2">
                        <label class="mb-3">Status Training</label>
                        <multiselect
                            v-model="status"
                            :options="statuses"
                            label="name"
                            track-by="id"
                            placeholder="Pilih status training"
                            class="multi-form-custom"
                            :show-labels="false"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-2 mt-2">
                        <label class="invisible mb-3">invisible</label>
                        <button
                            class="btn px-5 w-100"
                            :class="{
                                'btn-clear-disabled': !canFilter,
                                'btn-outline-red': canFilter,
                            }"
                            :disabled="!canFilter"
                            @click="clearFilter()"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-2 mt-2">
                        <label class="invisible mb-3">invisible</label>
                        <button
                            class="btn px-5 w-100"
                            :class="{
                                'btn-filter-disabled': !canFilter,
                                'btn-green': canFilter,
                            }"
                            :disabled="!canFilter"
                            @click="applyFilter()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="isLoading">
                <ListLoader />
            </div>
            <div v-else>
                <div
                    class="d-flex justify-content-between align-items-center mt-4"
                >
                    <p class="mb-0">
                        Menampilkan
                        <span class="fw-bold"
                            >{{ pagination.start }}-{{ pagination.end }} </span
                        >Data dari
                        <span class="fw-bold">{{ totalData }} </span>Data
                    </p>
                    <div class="d-flex gap-3 align-items-center">
                        <p class="mb-0">Rows Per Page</p>
                        <select
                            class="form-control form-select w-select bg-row"
                            v-model="rowsPerPage"
                            @change="changeRowsPerPage()"
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
                <div class="table-responsive-custom mb-4">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nama Agent
                                </th>
                                <th class="text-center" scope="col">
                                    Nomor Telepon
                                </th>
                                <th class="text-center" scope="col">
                                    Kota/Kabupaten
                                </th>
                                <th class="text-center" scope="col">
                                    Nama Koordinator
                                </th>
                                <th class="text-center" scope="col">
                                    Status Training
                                </th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr></tr>
                            <tr
                                v-for="(agent, index) in agents"
                                :key="agent.id"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>
                                    <router-link
                                        class="text-link"
                                        :to="`/training-sales-affiliate/detail/${agent.id}`"
                                    >
                                        {{ agent.name }}
                                    </router-link>
                                </td>
                                <td class="text-center">
                                    {{ agent.phone_number }}
                                </td>
                                <td>{{ agent.regency }}</td>
                                <td>{{ agent.coordinator_name }}</td>
                                <td class="text-center d-flex flex-column">
                                    <div
                                        v-if="agent.is_trained"
                                        class="tag rounded-pill w-100 capitalize trained"
                                    >
                                        Trained
                                    </div>
                                    <div
                                        v-else
                                        class="tag rounded-pill w-100 capitalize untrained"
                                    >
                                        Untrained
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div v-if="agent.is_trained">
                                        <img
                                            src="../../assets/check-gray.png"
                                            width="20"
                                            class="me-2"
                                            alt=""
                                        />
                                    </div>
                                    <div v-else>
                                        <a
                                            @click="openModalConfirm(agent.id)"
                                            href="javascript:void(0)"
                                            class="text-green size-20 me-2"
                                        >
                                            <img
                                                src="../../assets/check-green.png"
                                                width="20"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center mt-section" v-if="agents.length === 0">
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalData"
                    :per-page="rowsPerPage"
                    align="right"
                    @change="changePage"
                ></b-pagination>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/info-yellow.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Apakah Agent ini Sudah Menyelesaikan Proses Training?
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-outline-red w-100 btn-lg"
                                @click="modalConfirm = false"
                            >
                                Batal
                            </button>
                        </div>
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-green w-100 btn-lg"
                                @click="storeAccept()"
                                :disabled="isAcceptLoading"
                            >
                                <span
                                    v-if="isAcceptLoading"
                                    class="spinner-border spinner-border-sm me-2"
                                ></span>
                                Iya
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/check-new.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Agent siap melakukan penjualan!
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-12 mt-4">
                            <button
                                class="btn btn-green w-100 btn-lg"
                                @click="
                                    modalSuccess = false;
                                    fetchData();
                                "
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { debounce } from "lodash";
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
        ListLoader,
    },
    data() {
        return {
            showFilter: false,
            search: null,
            regency: null,
            regencies: [],
            status: null,
            statuses: [
                { name: "Untrained", id: "untrained" },
                { name: "Trained", id: "trained" },
            ],
            modalConfirm: false,
            modalSuccess: false,
            agents: [],
            totalData: 0,
            rowsPerPage: 10,
            currentPage: 1,
            isLoading: false,
            isAcceptLoading: false,
            agentId: null,
        };
    },
    created() {
        this.getRegency();
        this.fetchData();
        this.debouncedFilterData = debounce(this.filterData, 1000);
    },
    computed: {
        pagination() {
            const start = (this.currentPage - 1) * this.rowsPerPage + 1;
            const end = Math.min(
                this.currentPage * this.rowsPerPage,
                this.totalData
            );
            return { start, end };
        },
        canFilter() {
            return this.regency !== null || this.status !== null;
        },
    },
    methods: {
        openModalConfirm(value) {
            this.agentId = value;
            this.modalConfirm = true;
        },
        getRegency() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.regencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        fetchData() {
            this.isLoading = true;

            const params = {
                search: this.search ? this.search : null,
                regency_id: this.regency ? this.regency.id : null,
                status: this.status
                    ? this.status.id === "trained"
                        ? true
                        : false
                    : null,
                limit: this.rowsPerPage,
                page: this.currentPage,
            };

            Api.get(`${process.env.VUE_APP_SERVICE_URL}/sa/admin/trained`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                params,
            })
                .then((response) => {
                    const data = response.data.data;
                    this.agents = data.list_data;
                    this.totalData = data.total_data;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        toggleFilter() {
            this.showFilter = !this.showFilter;
        },
        clearFilter() {
            this.regency = null;
            this.status = null;
            this.fetchData();
        },
        applyFilter() {
            this.currentPage = 1;
            this.fetchData();
        },
        filterData() {
            this.currentPage = 1;
            this.fetchData();
        },
        changeRowsPerPage() {
            this.currentPage = 1;
            this.fetchData();
        },
        changePage(page) {
            this.currentPage = page;
            this.fetchData();
        },
        storeAccept() {
            this.isAcceptLoading = true;
            const data = {
                id: [this.agentId],
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/sa/admin/trained/accept`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isAcceptLoading = false;
                    this.modalConfirm = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.isAcceptLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
.trained {
    background-color: #d8e7d3 !important;
    color: #33711e !important;
}
.untrained {
    background-color: #feead4 !important;
    color: #d17e20 !important;
}
.text-link {
    color: #3c87b1 !important;
}
.btn-clear-disabled {
    border: 1px solid #d2d2d5 !important;
    color: #d2d2d5 !important;
}
.btn-filter-disabled {
    background-color: #d2d2d5 !important;
    color: white !important;
    border: none !important;
}
.bg-row {
    background-color: #f6faff;
}

th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.btn-filter {
    border: 1px solid #d9d9d9;
}
.btn-filter:hover {
    border: 1px solid #d9d9d9;
}
</style>
