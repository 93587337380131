<template>
    <div class="padding-container">
        <div class="row">
            <div class="col-md-2">
                <center>
                    <template v-if="data.avatar_url != null">
                        <div
                            class="user-avatar-sidebar"
                            :style="{
                                'background-image':
                                    'url(' + data.avatar_url + ')',
                            }"
                        ></div>
                    </template>
                    <template v-else>
                        <div
                            class="user-avatar-sidebar"
                            :style="{
                                'background-image':
                                    'url(' +
                                    require('../../assets/user.jpg') +
                                    ')',
                            }"
                        ></div>
                    </template>
                </center>
            </div>
            <div class="col-md-10">
                <div class="d-flex mb-4">
                    <h3 class="fw-bold">
                        <a
                            href="javascript:void(0)"
                            @click="$router.go(-1)"
                            class="text-black me-2"
                        >
                            <i class="fa fa-angle-left size-24"></i>
                        </a>
                        Detail Akun Driver
                        <span
                            class="tag radius px-4 capitalize ms-3"
                            v-bind:class="{
                                panding: data.driver_truck.status == 'pending',
                                resubmit:
                                    data.driver_truck.status == 'resubmit',
                                reject: data.driver_truck.status == 'reject',
                                banned: data.driver_truck.status == 'banned',
                            }"
                        >
                            {{ data.driver_truck.status }}
                        </span>
                    </h3>
                    <div
                        class="ms-auto"
                        v-if="
                            data.driver_truck.status != 'reject' &&
                            data.driver_truck.status != 'banned' &&
                            data.driver_truck.status != 'accept'
                        "
                    >
                        <button
                            type="button"
                            class="btn btn-primary btn-md px-4 me-2"
                            @click="openModalReject()"
                        >
                            Reject Driver
                        </button>
                        <button
                            type="button"
                            class="btn btn-green btn-md px-4"
                            @click="showModalDriver()"
                        >
                            Accept Driver
                        </button>
                    </div>
                </div>
                <div class="box">
                    <div class="row">
                        <div class="col-6 mb-3">
                            <label class="fw-semibold"> PIC Driver </label>
                            <div class="form-box mt-2">
                                {{
                                    data.driver_truck.referral?.name
                                        ? data.driver_truck.referral.name
                                        : "-"
                                }}
                            </div>
                        </div>
                        <div class="col-6 mb-3">
                            <label class="fw-semibold"> Approve By </label>
                            <div class="form-box mt-2">
                                {{
                                    data.driver_truck.approver?.name
                                        ? data.driver_truck.approver.name
                                        : "-"
                                }}
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="fw-semibold">
                                Tanggal Registrasi
                            </label>
                            <div class="form-box mt-2">
                                {{ dateFormat(data.created_at) }}
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="fw-semibold">
                                Tanggal Bergabung
                            </label>
                            <div class="form-box mt-2">
                                {{
                                    dateFormat(
                                        data.driver_truck.accepted_at ?? ""
                                    )
                                }}
                            </div>
                        </div>
                        <div class="col-6 mb-3">
                            <label class="fw-semibold"> Nama Lengkap </label>
                            <div class="form-box mt-2">
                                {{ data.name ? data.name : "-" }}
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="fw-semibold"> Email </label>
                            <div class="form-box mt-2">
                                {{ data.email ? data.email : "-" }}
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="fw-semibold"> Nomor Telepon </label>
                            <div class="form-box mt-2">
                                {{ data.phone ? data.phone : "-" }}
                            </div>
                        </div>
                        <div class="col-md-12 mb-3">
                            <hr />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label class="fw-semibold"> Merek Kendaraan </label>
                            <div class="form-box mt-2">
                                {{
                                    data.driver_truck.fleet?.fleet_model
                                        ? data.driver_truck.fleet?.fleet_model
                                        : "-"
                                }}
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label class="fw-semibold"> Jenis Mobil </label>
                            <div class="form-box mt-2">
                                {{
                                    data.driver_truck.fleet?.fleet_type_label
                                        ? data.driver_truck.fleet
                                              ?.fleet_type_label
                                        : "-"
                                }}
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label class="fw-semibold"> Nomor Kendaraan </label>
                            <div class="form-box mt-2">
                                {{
                                    data.driver_truck.fleet?.fleet_number
                                        ? data.driver_truck.fleet?.fleet_number
                                        : "-"
                                }}
                            </div>
                        </div>
                        <div class="col-md-12 mb-3">
                            <hr />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label class="fw-semibold">
                                Nama Pemilik Rekening
                            </label>
                            <div class="form-box mt-2">
                                {{
                                    data?.bank_account?.account_name
                                        ? data?.bank_account?.account_name
                                        : "-"
                                }}
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label class="fw-semibold"> Rekening Bank </label>
                            <div class="form-driver">
                                <div class="form-box mt-2">
                                    {{
                                        data?.bank_account?.account_number
                                            ? data?.bank_account?.account_number
                                            : "-"
                                    }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label class="fw-semibold"> Nama Bank </label>
                            <div class="form-box mt-2">
                                {{
                                    data?.bank_account?.bank?.name
                                        ? data?.bank_account?.bank?.name
                                        : "-"
                                }}
                            </div>
                        </div>
                        <div class="col-md-12 mb-3">
                            <hr />
                        </div>
                        <div class="col-md-12 mb-3">
                            <label class="fw-semibold"> Alamat Domisili </label>
                            <div class="form-box mt-2">
                                {{
                                    data?.driver_truck?.domicile_address
                                        ? data?.driver_truck?.domicile_address
                                        : "-"
                                }}
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="fw-semibold"> Provinsi </label>
                            <div class="form-box mt-2">
                                {{
                                    data.driver_truck?.province?.name
                                        ? data.driver_truck?.province?.name
                                        : "-"
                                }}
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="fw-semibold"> Kota/Kabupaten </label>
                            <div class="form-box mt-2">
                                {{
                                    data.driver_truck?.regency?.name
                                        ? data.driver_truck?.regency?.name
                                        : "-"
                                }}
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="fw-semibold"> Kecamatan </label>
                            <div class="form-box mt-2">
                                {{
                                    data.driver_truck?.district?.name
                                        ? data.driver_truck?.district?.name
                                        : "-"
                                }}
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label class="fw-semibold"> Kelurahan </label>
                            <div class="form-box mt-2">
                                {{
                                    data.driver_truck?.subdistrict?.name
                                        ? data.driver_truck?.subdistrict?.name
                                        : "-"
                                }}
                            </div>
                        </div>
                        <div class="col-md-4 mt-3">
                            <label class="fw-semibold">Foto Driver</label>
                            <div class="mt-2">
                                <template v-if="data.avatar_url">
                                    <viewer :images="[]" :options="options">
                                        <img
                                            class="image-cover"
                                            :key="data.avatar_url"
                                            :src="data.avatar_url"
                                        />
                                    </viewer>
                                </template>
                                <template v-else>
                                    <div
                                        class="image-default"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                require('../../assets/empty.png') +
                                                ')',
                                        }"
                                    ></div>
                                </template>
                            </div>
                        </div>
                        <div class="col-md-4 mt-3">
                            <label class="fw-semibold">Foto KTP</label>
                            <div class="mt-2">
                                <template
                                    v-if="
                                        data.driver_truck.identity_picture_url
                                    "
                                >
                                    <viewer :images="[]" :options="options">
                                        <img
                                            class="image-cover"
                                            :key="
                                                data.driver_truck
                                                    .identity_picture_url
                                            "
                                            :src="
                                                data.driver_truck
                                                    .identity_picture_url
                                            "
                                        />
                                    </viewer>
                                </template>
                                <template v-else>
                                    <div
                                        class="image-default"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                require('../../assets/empty.png') +
                                                ')',
                                        }"
                                    ></div>
                                </template>
                            </div>
                        </div>
                        <div class="col-md-4 mt-3">
                            <label class="fw-semibold">Foto SIM</label>
                            <div class="mt-2">
                                <template
                                    v-if="data.driver_truck.license_picture_url"
                                >
                                    <viewer :images="[]" :options="options">
                                        <img
                                            class="image-cover"
                                            :key="
                                                data.driver_truck
                                                    .license_picture_url
                                            "
                                            :src="
                                                data.driver_truck
                                                    .license_picture_url
                                            "
                                        />
                                    </viewer>
                                </template>
                                <template v-else>
                                    <div
                                        class="image-default"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                require('../../assets/empty.png') +
                                                ')',
                                        }"
                                    ></div>
                                </template>
                            </div>
                        </div>

                        <div class="col-md-4 mt-4">
                            <label class="fw-semibold">
                                Foto SKCK (Opsional)</label
                            >
                            <div class="mt-2">
                                <template
                                    v-if="data.driver_truck?.skck_picture_url"
                                >
                                    <viewer :images="[]" :options="options">
                                        <img
                                            class="image-cover"
                                            :key="
                                                data.driver_truck
                                                    ?.skck_picture_url
                                            "
                                            :src="
                                                data.driver_truck
                                                    ?.skck_picture_url
                                            "
                                        />
                                    </viewer>
                                </template>
                                <template v-else>
                                    <div
                                        class="image-default"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                require('../../assets/empty.png') +
                                                ')',
                                        }"
                                    ></div>
                                </template>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4">
                            <label class="fw-semibold">Foto STNK</label>
                            <div class="mt-2">
                                <template
                                    v-if="
                                        data.driver_truck.fleet
                                            ?.registration_picture_url
                                    "
                                >
                                    <viewer :images="[]" :options="options">
                                        <img
                                            class="image-cover"
                                            :key="
                                                data.driver_truck.fleet
                                                    .registration_picture_url
                                            "
                                            :src="
                                                data.driver_truck.fleet
                                                    .registration_picture_url
                                            "
                                        />
                                    </viewer>
                                </template>
                                <template v-else>
                                    <div
                                        class="image-default"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                require('../../assets/empty.png') +
                                                ')',
                                        }"
                                    ></div>
                                </template>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4">
                            <label class="fw-semibold"> Foto KIR</label>
                            <div class="mt-2">
                                <template
                                    v-if="data?.driver_truck?.kir_picture_url"
                                >
                                    <viewer :images="[]" :options="options">
                                        <img
                                            class="image-cover"
                                            :key="
                                                data?.driver_truck
                                                    ?.kir_picture_url
                                            "
                                            :src="
                                                data?.driver_truck
                                                    ?.kir_picture_url
                                            "
                                        />
                                    </viewer>
                                </template>
                                <template v-else>
                                    <div
                                        class="image-default"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                require('../../assets/empty.png') +
                                                ')',
                                        }"
                                    ></div>
                                </template>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4">
                            <label class="fw-semibold">
                                Foto Armada (Depan)</label
                            >
                            <div class="mt-2">
                                <template
                                    v-if="
                                        data.driver_truck.fleet
                                            ?.fleet_picture_url
                                    "
                                >
                                    <viewer :images="[]" :options="options">
                                        <img
                                            class="image-cover"
                                            :key="
                                                data.driver_truck.fleet
                                                    ?.fleet_picture_url
                                            "
                                            :src="
                                                data.driver_truck.fleet
                                                    ?.fleet_picture_url
                                            "
                                        />
                                    </viewer>
                                </template>
                                <template v-else>
                                    <div
                                        class="image-default"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                require('../../assets/no-photo.png') +
                                                ')',
                                        }"
                                    ></div>
                                </template>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4">
                            <label class="fw-semibold">
                                Foto Armada (Samping)</label
                            >
                            <div class="mt-2">
                                <template
                                    v-if="
                                        data?.driver_truck?.fleet
                                            ?.fleet_picture_side_url
                                    "
                                >
                                    <viewer :images="[]" :options="options">
                                        <img
                                            class="image-cover"
                                            :key="
                                                data?.driver_truck?.fleet
                                                    ?.fleet_picture_side_url
                                            "
                                            :src="
                                                data?.driver_truck?.fleet
                                                    ?.fleet_picture_side_url
                                            "
                                        />
                                    </viewer>
                                </template>
                                <template v-else>
                                    <div
                                        class="image-default"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                require('../../assets/empty.png') +
                                                ')',
                                        }"
                                    ></div>
                                </template>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4">
                            <label class="fw-semibold">
                                Foto Armada (Belakang)</label
                            >
                            <div class="mt-2">
                                <template
                                    v-if="
                                        data?.driver_truck?.fleet
                                            ?.fleet_picture_back_url
                                    "
                                >
                                    <viewer :images="[]" :options="options">
                                        <img
                                            class="image-cover"
                                            :key="
                                                data?.driver_truck?.fleet
                                                    ?.fleet_picture_back_url
                                            "
                                            :src="
                                                data?.driver_truck?.fleet
                                                    ?.fleet_picture_back_url
                                            "
                                        />
                                    </viewer>
                                </template>
                                <template v-else>
                                    <div
                                        class="image-default"
                                        :style="{
                                            'background-image':
                                                'url(' +
                                                require('../../assets/empty.png') +
                                                ')',
                                        }"
                                    ></div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalDriver"></div>
            <div class="modal-body-chat vsm" v-if="modalDriver">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-ekstra-bold text-center">
                            <img
                                src="../../assets/info-yellow.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-ekstra-bold text-center">
                        Apakah Kamu Yakin ingin menambahkan data ini sebagai
                        Driver?
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-outline-black w-100 btn-lg"
                                @click="modalDriver = false"
                            >
                                Cancel
                            </button>
                        </div>
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-green w-100 btn-lg"
                                @click="acceptDriver()"
                                :disabled="is_accept"
                            >
                                <span
                                    class="spinner-border spinner-border-sm me-2"
                                    v-if="is_accept"
                                ></span>
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalReject"></div>
            <div class="modal-body-chat vsm" v-if="modalReject">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/info-yellow.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Pendaftaran driver ini akan ditolak!
                    </div>
                    <div class="text-gray-dashboard text-center">
                        Mohon lengkapi checklist sheet dan berikan alasan
                        penolakan
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-outline-red w-100 btn-lg"
                                @click="modalReject = false"
                            >
                                Batal
                            </button>
                        </div>
                        <div class="col-md-6 mt-4">
                            <a
                                :href="`/trawltruck/driver/registration/reject/${$route.params.id}`"
                                class="btn btn-green w-100 btn-lg"
                            >
                                Ok
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import moment from "moment";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";

export default {
    components: {
        Viewer,
    },
    data() {
        return {
            moment: moment,
            data: {
                bank_account: {
                    bank: {
                        name: "",
                        account_number: "",
                    },
                },
                driver_truck: {
                    fleet: {},
                    rejections: [],
                },
            },
            type: this.$route.params.type,
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
            modalDriver: false,
            is_accept: false,
            reject_id: null,
            modalReject: false,
        };
    },
    created() {
        this.getData();
    },
    methods: {
        dateFormat(date) {
            if (date == "") return "-";
            return moment(date).format("DD MMMM YYYY | HH:mm");
        },
        getData() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/driver/detail`,
                {
                    params: { user_id: this.$route.params.id },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data;
                })
                .catch(function (err) {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: `${err}`,
                    });
                    console.error(err);
                });
        },
        showModalDriver() {
            this.modalDriver = true;
        },
        acceptDriver() {
            this.is_accept = true;
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/register/accept`,
                {
                    user_id: this.$route.params.id,
                },
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Berhasil ditambahkan sebagai driver",
                    });
                    this.is_accept = false;
                    this.modalDriver = false;
                    setTimeout(() => {
                        window.location.href = "/trawltruck/driver/account";
                    }, 1000);
                })
                .catch((err) => {
                    this.is_accept = false;
                    this.modalDriver = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        openModalReject() {
            this.modalReject = true;
        },
    },
};
</script>

<style scoped>
.user-avatar-sidebar {
    width: 110px;
    height: 110px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100px;
    position: relative;
    border: 2px solid #f8f9fa;
}
.image-cover {
    border-radius: 10px;
    border: 2px solid #e2e3e5;
    width: 100%;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
}
.image-default {
    width: 100%;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 6px;
    border: 2px solid #e2e3e5;
}
</style>
