<template>
    <div class="padding-container">
        <div class="fw-bold">
            <a href="/trawltruck/mitra" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4 m-mt-0 p-0">
            <div class="pe-4 ps-4">
                <div class="d-flex align-items-center no-flex">
                    <h3 class="fw-bold mb-0 mt-4">Detail Data Visit</h3>
                    <div class="ms-auto">
                        <a
                            :href="`/trawltruck/mitra/edit/${detail.id}`"
                            class="btn btn-green px-4 mt-4"
                            v-if="detail.is_can_edit == true"
                        >
                            Edit Data
                        </a>
                        <button class="btn btn-green px-4 mt-4" v-else disabled>
                            Edit Data
                        </button>
                    </div>
                </div>
            </div>
            <div class="pe-4 ps-4 mt-4">
                <label class="fw-bold mb-2">VM Truk</label>
                <div class="form-box capitalize">
                    {{ detail.vm_name ? detail.vm_name : "-" }}
                </div>
            </div>
            <div class="pe-4 ps-4 mt-4">
                <label class="fw-bold mb-2">Jenis Mitra</label>
                <div class="form-box capitalize">
                    {{ detail.type ? detail.type : "-" }}
                </div>
            </div>
            <div class="mt-4">
                <hr />
            </div>
            <div class="row ps-4 pr-4">
                <div class="col-md-12">
                    <h3 class="fw-bold mb-0">
                        <template v-if="detail.type == 'individual'">
                            Informasi Driver
                        </template>
                        <template v-else> Informasi Perusahaan </template>
                    </h3>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        <template v-if="detail.type == 'individual'">
                            Nama Calon Driver
                        </template>
                        <template v-else> Nama Perusahaan </template>
                    </label>
                    <div class="form-box capitalize">
                        {{ detail.name_visit ? detail.name_visit : "-" }}
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        <template v-if="detail.type == 'individual'">
                            Nomor Telepon
                        </template>
                        <template v-else>Nomor Telepon Perusahaan </template>
                    </label>
                    <div class="form-box">
                        {{ detail.phone_visit ? detail.phone_visit : "-" }}
                    </div>
                </div>
                <div class="col-md-6 mt-3" v-if="detail.type == 'corporate'">
                    <label class="fw-bold mb-2"
                        >Nama Penanggung Jawab (Opsional)</label
                    >
                    <div class="form-box">
                        {{ detail.pic_name ? detail.pic_name : "-" }}
                    </div>
                </div>
                <div class="col-md-6 mt-3" v-if="detail.type == 'corporate'">
                    <label class="fw-bold mb-2">
                        Nomor Telepon Penanggung Jawab (Opsional)
                    </label>
                    <div class="form-box">
                        {{ detail.pic_phone ? detail.pic_phone : "-" }}
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">Kota/Kabupaten</label>
                    <div class="form-box">
                        {{
                            detail.geo_regency.name
                                ? detail.geo_regency.name
                                : "-"
                        }}
                    </div>
                </div>
                <div class="col-md-6 mt-3">
                    <label class="fw-bold mb-2">
                        <template v-if="detail.type == 'individual'">
                            Alamat Domisili
                        </template>
                        <template v-else>Alamat Perusahaan </template>
                    </label>
                    <div class="form-box">
                        {{ detail.address_visit ? detail.address_visit : "-" }}
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <hr />
            </div>
            <div class="row pe-4 ps-4 pb-4">
                <div class="col-md-12">
                    <h3 class="fw-bold mb-0">Informasi Armada</h3>
                </div>
                <div class="col-md-12 mt-3 pb-2">
                    <template v-if="detail.type == 'individual'">
                        <label class="fw-bold mb-2">Jenis Armada</label>
                        <div class="form-box capitalize">
                            {{ detail.fleets[0]?.name }}
                        </div>
                    </template>
                    <template v-else>
                        <div class="table-responsive-custom">
                            <table class="table table-bordered mt-4">
                                <thead class="table-light">
                                    <tr>
                                        <th class="text-center" scope="col">
                                            Jenis Armada
                                        </th>
                                        <th class="text-center" scope="col">
                                            Jumlah Armada
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(data, index) in detail.fleets"
                                        :key="index"
                                    >
                                        <td class="capitalize">
                                            {{ data.name }}
                                        </td>
                                        <td class="text-center">
                                            {{ data.count_fleet }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";

export default {
    components: {},
    name: "trawltruckMitraDetail",
    data() {
        return {
            detail: {
                geo_regency: {},
                fleets: [],
            },
        };
    },
    created() {
        this.getList();
    },
    computed: {},
    methods: {
        getList() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/partner/truck/vm-report/detail`,
                {
                    params: { vm_id: this.$route.params.id },
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detail = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
</style>
