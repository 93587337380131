<template>
    <div class="padding-container">
        <div class="content-load" v-if="isLoadData"></div>
        <div id="loader" v-if="isLoadData"></div>
        <div
            class="text-black size-16 text-gray-dashboard fw-semibold mb-4 cursor-pointer"
            @click="$router.go(-1)"
        >
            <i class="fa fa-angle-left me-2"></i>
            Kembali
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="box my-3">
                    <div
                        class="d-flex justify-content-between align-items-center"
                    >
                        <h4>Detail Pencairan Komisi</h4>
                        <div
                            v-if="detail.status === 'pending'"
                            class="tag rounded-pill capitalize badge-pending px-4"
                        >
                            Pending
                        </div>
                        <div
                            v-if="detail.status === 'transfer'"
                            class="tag rounded-pill capitalize badge-transferred px-4"
                        >
                            Transferred
                        </div>
                    </div>
                    <div class="table-responsive-custom">
                        <table class="table mt-4">
                            <tr>
                                <td>Periode</td>
                                <td>:</td>
                                <td>{{ detail.period }}</td>
                            </tr>
                            <tr>
                                <td>Nama Agent</td>
                                <td>:</td>
                                <td>{{ detail.agent_name }}</td>
                            </tr>
                            <tr>
                                <td>Bank Tujuan</td>
                                <td>:</td>
                                <td>{{ detail.bank_name }}</td>
                            </tr>
                            <tr>
                                <td>Nama Pemilik Rekening</td>
                                <td>:</td>
                                <td>{{ detail.account_name }}</td>
                            </tr>
                            <tr>
                                <td>Nomor Rekening</td>
                                <td>:</td>
                                <td>{{ detail.account_number }}</td>
                            </tr>
                            <tr>
                                <td>Nominal Pencairan</td>
                                <td>:</td>
                                <td>{{ currency(detail.amount) }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="box my-3">
                    <h4>Bukti Transfer Pencairan Komisi</h4>
                    <p>Bukti Transfer</p>
                    <input
                        type="file"
                        ref="fileInput"
                        hidden
                        accept=".jpg, .jpeg, .png"
                        @change="handleFileChange"
                    />
                    <div v-if="detail.status === 'pending'">
                        <div v-if="!selectedImage" @click="triggerFileInput">
                            <svg
                                class="w-100 cursor-pointer"
                                xmlns="http://www.w3.org/2000/svg"
                                width="484"
                                height="240"
                                viewBox="0 0 484 240"
                                fill="none"
                            >
                                <rect
                                    x="0.5"
                                    y="0.5"
                                    width="482.5"
                                    height="239"
                                    rx="7.5"
                                    fill="white"
                                />
                                <rect
                                    x="0.5"
                                    y="0.5"
                                    width="482.5"
                                    height="239"
                                    rx="7.5"
                                    stroke="#D2D2D5"
                                />
                                <path
                                    d="M250.75 115.5C250.325 115.5 249.969 115.356 249.682 115.068C249.395 114.78 249.251 114.424 249.25 114V112.5H247.75C247.325 112.5 246.969 112.356 246.682 112.068C246.395 111.78 246.251 111.424 246.25 111C246.25 110.575 246.394 110.219 246.682 109.932C246.97 109.645 247.326 109.501 247.75 109.5H249.25V108C249.25 107.575 249.394 107.219 249.682 106.932C249.97 106.645 250.326 106.501 250.75 106.5C251.175 106.5 251.531 106.644 251.819 106.932C252.107 107.22 252.251 107.576 252.25 108V109.5H253.75C254.175 109.5 254.531 109.644 254.819 109.932C255.107 110.22 255.251 110.576 255.25 111C255.25 111.425 255.106 111.781 254.818 112.069C254.53 112.357 254.174 112.501 253.75 112.5H252.25V114C252.25 114.425 252.106 114.782 251.818 115.07C251.53 115.358 251.174 115.501 250.75 115.5ZM234.25 127.5H249.25C249.55 127.5 249.775 127.363 249.925 127.088C250.075 126.813 250.05 126.55 249.85 126.3L245.725 120.787C245.575 120.587 245.375 120.487 245.125 120.487C244.875 120.487 244.675 120.587 244.525 120.787L240.625 126L237.85 122.287C237.7 122.087 237.5 121.987 237.25 121.987C237 121.987 236.8 122.087 236.65 122.287L233.65 126.3C233.45 126.55 233.425 126.813 233.575 127.088C233.725 127.363 233.95 127.5 234.25 127.5ZM231.25 133.5C230.425 133.5 229.719 133.206 229.132 132.618C228.545 132.03 228.251 131.324 228.25 130.5V109.5C228.25 108.675 228.544 107.969 229.132 107.382C229.72 106.795 230.426 106.501 231.25 106.5H243.25C243.675 106.5 244.032 106.644 244.32 106.932C244.608 107.22 244.751 107.576 244.75 108V112.5C244.75 112.925 244.894 113.281 245.182 113.569C245.47 113.857 245.826 114.001 246.25 114H247.75V115.5C247.75 115.925 247.894 116.282 248.182 116.57C248.47 116.858 248.826 117.001 249.25 117H253.75C254.175 117 254.531 117.144 254.819 117.432C255.107 117.72 255.251 118.076 255.25 118.5V130.5C255.25 131.325 254.957 132.032 254.37 132.62C253.783 133.208 253.076 133.501 252.25 133.5H231.25Z"
                                    fill="#D2D2D5"
                                />
                            </svg>
                        </div>
                        <div
                            v-else
                            class="border d-flex justify-content-center h-15 align-items-center rounded mb-4 cursor-pointer"
                            @click="triggerFileInput"
                        >
                            <img
                                :src="selectedImage"
                                class="w-100 h-100 object-fit-cover rounded"
                                alt="Selected image"
                            />
                        </div>
                    </div>
                    <div
                        v-if="detail.status === 'transfer'"
                        class="border d-flex justify-content-center h-15 align-items-center rounded mb-4 cursor-pointer"
                    >
                        <viewer
                            :options="options"
                            :images="detail.attachment"
                            class="w-100 h-100"
                        >
                            <img
                                class="w-100 h-100 object-fit-cover rounded"
                                :key="detail.attachment"
                                :src="detail.attachment"
                            />
                        </viewer>
                    </div>
                    <button
                        class="btn btn-green w-100 py-2"
                        v-if="detail.status === 'pending'"
                        :disabled="!selectedImage"
                        @click="modalConfirm = true"
                    >
                        Simpan
                    </button>
                </div>
            </div>
            <div class="col-md-6">
                <div class="box my-3">
                    <h5>Detail Nominal Pencairan</h5>
                    <div class="d-flex justify-content-between text-green">
                        <p>{{ detail.list_receipts.length }} Resi</p>
                        <p>{{ currency(detail.amount) }}</p>
                    </div>
                    <div class="accordion">
                        <div
                            v-for="(
                                list_receipt, index
                            ) in detail.list_receipts"
                            :key="index"
                        >
                            <div
                                v-b-toggle="'accordion-' + (index + 1)"
                                class="d-flex bg-accordion my-3 justify-content-between align-items-center rounded p-2 px-3 fw-bold"
                            >
                                <p class="mb-0">
                                    {{ list_receipt.receipt_code }}
                                </p>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M5 8L12 16"
                                        stroke="#1F1E2C"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                    />
                                    <path
                                        d="M19 8L12 16"
                                        stroke="#1F1E2C"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                    />
                                </svg>
                            </div>
                            <b-collapse
                                class="px-2"
                                :id="'accordion-' + (index + 1)"
                            >
                                <div
                                    class="d-flex justify-content-between my-2 align-items-center"
                                >
                                    <p class="mb-0">Jenis Layanan</p>
                                    <p class="mb-0 capitalize">
                                        Troben {{ list_receipt.product_label }}
                                    </p>
                                </div>
                                <div
                                    class="d-flex justify-content-between my-2 align-items-center"
                                >
                                    <p class="mb-0">Jenis Pengiriman</p>
                                    <p class="mb-0 capitalize">
                                        Pengiriman
                                        {{
                                            list_receipt.type.replace(/_/g, " ")
                                        }}
                                    </p>
                                </div>
                                <div
                                    class="d-flex justify-content-between my-2 align-items-center fw-bold"
                                >
                                    <p class="mb-0">Nominal Pendapatan</p>
                                    <p class="mb-0">
                                        {{ currency(list_receipt.amount) }}
                                    </p>
                                </div>
                            </b-collapse>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="box my-3">
                    <h5>Riwayat Pencairan Komisi</h5>
                    <div class="table-responsive-custom table-bottom mb-4">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Tanggal Pencairan
                                    </th>
                                    <th class="text-center" scope="col">
                                        Periode
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nominal Pencairan
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(history, index) in detail.histories"
                                    :key="index"
                                >
                                    <td class="text-center">{{ index + 1 }}</td>
                                    <td class="text-center">
                                        {{ history.disbursement_date }}
                                    </td>
                                    <td class="text-center">
                                        {{ history.period }}
                                    </td>
                                    <td class="text-end">
                                        {{ currency(history.amount) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/info-yellow.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Bukti Transfer Akan Diunggah
                    </div>
                    <div class="text-gray-dashboard text-center">
                        Apakah Anda yakin ingin mengunggah bukti transfer ini?
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-outline-red w-100 btn-lg"
                                @click="modalConfirm = false"
                            >
                                Batal
                            </button>
                        </div>
                        <div class="col-md-6 mt-4">
                            <button
                                class="btn btn-green w-100 btn-lg"
                                @click="handleSave()"
                            >
                                <span
                                    v-if="isConfirmLoading"
                                    class="spinner-border spinner-border-sm me-2"
                                ></span>
                                Iya
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="d-flex bd-highlight align-items-lg-center">
                    <div class="flex-grow-1 bd-highlight">
                        <div class="size-18 fw-bold text-center">
                            <img
                                src="../../assets/check-new.png"
                                width="70"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="size-18 fw-bold text-center">
                        Bukti Transfer Berhasil Diunggah
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-12 mt-4">
                            <router-link
                                class="btn btn-green w-100 btn-lg"
                                to="/disbursement-sales-affiliate"
                            >
                                OK
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";

export default {
    components: {
        Viewer,
    },
    data() {
        return {
            options: {
                title: false,
                toolbar: false,
                navbar: false,
                button: false,
            },
            detail: {
                list_receipts: [],
            },
            isLoadData: false,
            selectedImage: null,
            selectedFile: null,
            modalConfirm: false,
            modalSuccess: false,
            isConfirmLoading: false,
        };
    },
    created() {
        this.fetchData();
    },
    computed: {},
    methods: {
        fetchData() {
            this.isLoadData = true;

            const userId = Number(this.$route.params.id);
            const period = this.$route.params.period;

            const payload = {
                user_id: userId,
                period: period,
            };

            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/sa/admin/disbursement/detail`,
                {
                    params: payload,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((response) => {
                    const data = response.data.data;
                    this.detail = data;
                    this.isLoadData = false;
                })
                .catch((err) => {
                    this.isLoadData = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text:
                            err.response?.data?.message || "An error occurred",
                    });
                });
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedFile = file;
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.selectedImage = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        handleSave() {
            this.isConfirmLoading = true;
            if (!this.selectedFile) return;

            const formData = new FormData();
            formData.append("attachment", this.selectedFile);
            formData.append("user_id", this.$route.params.id);
            formData.append("period", this.$route.params.period);
            formData.append("amount", this.detail.amount);

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/sa/admin/disbursement/store`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isConfirmLoading = false;
                    this.modalConfirm = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.modalConfirm = false;
                    this.isConfirmLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text:
                            err.response?.data?.message || "An error occurred",
                    });
                });
        },
    },
};
</script>

<style scoped>
.table-bottom th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
.table-bottom tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
.table-bottom td {
    padding: 10px !important;
}
.table-bottom .table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.table-bottom .table tr:last-child {
    border-bottom: 1px solid #e8e8e9 !important;
}
.bg-accordion {
    background-color: #d8e7d3;
}
.btn-green:disabled {
    background: #d2d2d5 !important;
    border: none;
}
.h-15 {
    height: 15rem;
}
.table-responsive-custom .table {
    border-collapse: collapse;
}

.table-responsive-custom .table tr {
    border-bottom: 1px solid #e8e8e9;
}

.table-responsive-custom .table tr:last-child {
    border-bottom: none;
}

.table-responsive-custom .table td {
    padding: 10px;
}
.badge-pending {
    background-color: #feead4;
    color: #d17e20;
}
.badge-transferred {
    background-color: #d8e7d3;
    color: #33711e;
}
.form-input {
    background: rgba(239, 241, 249, 0.6);
    border-width: 0px;
    border: none;
    outline: none;
    border-radius: 10px;
    width: 258px;
    height: 40px;
    padding: 10px;
}
.image-cover {
    border-radius: 10px;
    width: 100%;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
    border: 2px solid #f7f7f7;
}
.image-default {
    width: 100%;
    height: 150px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    border: 2px solid #f7f7f7;
}
.checkbox input[type="checkbox"] {
    display: none;
}
.form-check-input {
    display: none !important;
}
.form-check label {
    padding-left: 0;
    cursor: pointer;
}

.form-check label:before {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
    line-height: 18px;
    text-align: center;
    border: 1px solid #ccc;
    background: #fafafa;
    border-radius: 3px;
    font-family: "FontAwesome";
    cursor: pointer;
}
.form-check input[type="checkbox"]:checked + label::before {
    content: "\f00d";
    background: #faccd0;
    color: #e60013;
    border: 1px solid #e60013;
}
</style>
