<template>
    <div class="date-range-picker" ref="dateRangePicker">
        <button
            class="form-control w-100 text-left d-flex align-items-center text-placeholder"
            @click="handleButtonClick"
            :class="{ disabled: disabled }"
            :disabled="disabled"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="19"
                viewBox="0 0 25 24"
                fill="none"
                class="me-2"
            >
                <path
                    d="M3.3335 16.5V8.5C3.3335 6.01472 5.34821 4 7.8335 4H16.8335C19.3188 4 21.3335 6.01472 21.3335 8.5V16.5C21.3335 18.9853 19.3188 21 16.8335 21H7.8335C5.34821 21 3.3335 18.9853 3.3335 16.5Z"
                    fill="#61616A"
                    stroke="#61616A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9.3335 2.5V5.5"
                    stroke="#61616A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M15.3335 2.5V5.5"
                    stroke="#61616A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M3.3335 9H21.3335"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="square"
                />
                <circle cx="8.3335" cy="13" r="1" fill="white" />
                <circle cx="12.3335" cy="13" r="1" fill="white" />
                <circle cx="16.3335" cy="13" r="1" fill="white" />
                <circle cx="8.3335" cy="17" r="1" fill="white" />
                <circle cx="12.3335" cy="17" r="1" fill="white" />
                <circle cx="16.3335" cy="17" r="1" fill="white" />
            </svg>
            {{ displayedDateRange }}
        </button>
        <div v-if="isOpen" class="date-picker-popup card">
            <div class="card-body">
                <div class="calendar-container rounded">
                    <div>
                        <div
                            class="d-flex justify-content-between align-items-center mb-4"
                        >
                            <svg
                                @click="previousMonth()"
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="10"
                                viewBox="0 0 6 10"
                                fill="none"
                                class="cursor-pointer"
                            >
                                <path
                                    d="M4.83869 9.09438L0.775391 5.03605L4.83869 0.977722"
                                    stroke="#1F1E2C"
                                    stroke-width="1.3536"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <h6 class="current-month-year mb-0 fw-bold">
                                {{ currentViewTitle }}
                            </h6>
                            <svg
                                @click="nextMonth()"
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="10"
                                viewBox="0 0 6 10"
                                fill="none"
                                class="cursor-pointer"
                            >
                                <path
                                    d="M0.793213 9.09432L4.85651 5.03599L0.793213 0.977661"
                                    stroke="#1F1E2C"
                                    stroke-width="1.3536"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <hr class="divider mt-2 mb-3" />
                    </div>
                    <div class="calendar-grid">
                        <div class="calendar-header d-flex">
                            <div
                                v-for="day in [
                                    'Su',
                                    'Mo',
                                    'Tu',
                                    'We',
                                    'Th',
                                    'Fr',
                                    'Sa',
                                ]"
                                :key="day"
                                class="calendar-cell text-center"
                            >
                                {{ day }}
                            </div>
                        </div>
                        <div class="calendar-body">
                            <div
                                class="d-flex"
                                v-for="(week, weekIndex) in calendarDays"
                                :key="weekIndex"
                            >
                                <div
                                    v-for="{
                                        date,
                                        isCurrentMonth,
                                        isSelected,
                                        isInRange,
                                    } in week"
                                    :key="date.toISOString()"
                                    class="calendar-cell text-center"
                                    :class="{
                                        'text-muted': !isCurrentMonth,
                                        selected: isSelected,
                                        'in-range': isInRange,
                                        disabled: !isCurrentMonth,
                                    }"
                                    @click="handleDateClick(date)"
                                >
                                    {{ date.getDate() }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 small">
                        <p class="mb-1">
                            Tanggal terpilih:
                            <span class="text-green">
                                {{
                                    selectedDateRangeText === placeholderText
                                        ? "-"
                                        : selectedDateRangeText
                                }}
                            </span>
                        </p>
                    </div>
                    <div class="row mt-3">
                        <div class="col-6">
                            <button
                                class="btn btn-outline-yellow w-100"
                                @click="handleReset()"
                            >
                                Reset
                            </button>
                        </div>
                        <div class="col-6">
                            <button
                                class="btn btn-green w-100"
                                @click="handleSave()"
                            >
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.text-placeholder {
    color: #6c757d !important;
    font-size: 13px;
}
.date-range-picker {
    position: relative;
    width: 100%;
    max-width: 800px;
}

.date-picker-popup {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    width: 24rem;
    margin-top: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 768px) {
    .date-picker-popup {
        width: 24rem;
        max-width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .card-body {
        min-width: 24rem;
        padding-bottom: 1rem;
    }
}

.divider {
    border: none;
    border-top: 1px solid #dee2e6;
    margin: 0;
    width: 100%;
}

.calendar-container {
    background: white;
    padding: 1rem;
}

.calendar-cell {
    width: calc(100% / 7);
    padding: 0.5rem;
    cursor: pointer;
}

.calendar-header .calendar-cell {
    font-weight: bold;
    cursor: default;
}

.calendar-cell.selected {
    background-color: #3d8824;
    color: white !important;
}

.calendar-cell.in-range {
    background-color: #d8e7d3;
}

.calendar-cell.selected.in-range {
    background-color: #3d8824;
    color: #ffffff;
}

.calendar-cell.disabled {
    opacity: 0.5;
}

.btn-outline-yellow {
    color: #3d8824;
    background-color: transparent;
    border: 1px solid #3d8824;
}

.btn-outline-yellow:hover {
    color: #3d8824;
    background-color: transparent;
    border: 1px solid #3d8824;
}

.btn-green {
    background-color: #3d8824;
    color: white;
    border: 1px solid #3d8824;
}

.btn-green:hover {
    background-color: #357320;
    color: white;
    border: 1px solid #357320;
}

.text-green {
    color: #3d8824;
}

.cursor-pointer {
    cursor: pointer;
}
.disabled {
    cursor: not-allowed;
}
</style>

<script>
export default {
    name: "DateRangePicker",

    props: {
        value: {
            type: Object,
            default: () => ({
                from: null,
                to: null,
            }),
        },
        placeholderText: {
            type: String,
            default: "Select date",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            displayedDateRange: this.placeholderText,
            isOpen: false,
            currentDate: new Date(),
            selectedDateRange: {
                from: this.value.from,
                to: this.value.to,
            },
            isFirstClick: true,
            currentMonth: new Date().getMonth(),
            currentYear: new Date().getFullYear(),
            months: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
        };
    },

    watch: {
        value: {
            handler(newValue) {
                this.selectedDateRange = {
                    from: newValue.from,
                    to: newValue.to,
                };
                if (newValue.from === null && newValue.to === null) {
                    this.displayedDateRange = this.placeholderText;
                }
            },
            deep: true,
        },
    },

    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },

    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
    },

    computed: {
        selectedDateRangeText() {
            if (!this.selectedDateRange.from) return this.placeholderText;
            if (!this.selectedDateRange.to)
                return this.formatDate(this.selectedDateRange.from);
            return `${this.formatDate(
                this.selectedDateRange.from
            )} - ${this.formatDate(this.selectedDateRange.to)}`;
        },

        calendarDays() {
            const days = this.getMonthDays(
                new Date(this.currentYear, this.currentMonth)
            );
            const weeks = [];
            for (let i = 0; i < days.length; i += 7) {
                weeks.push(days.slice(i, i + 7));
            }
            return weeks;
        },
        currentViewTitle() {
            return this.formatMonthYear(
                new Date(this.currentYear, this.currentMonth)
            );
        },
    },

    methods: {
        handleButtonClick() {
            if (!this.disabled) {
                this.isOpen = !this.isOpen;
            }
        },
        handleClickOutside(event) {
            const dateRangePicker = this.$refs.dateRangePicker;
            if (
                dateRangePicker &&
                !dateRangePicker.contains(event.target) &&
                this.isOpen
            ) {
                this.isOpen = false;
            }
        },

        formatDate(date) {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${day}/${month.toString().padStart(2, "0")}/${year}`;
        },

        formatMonthYear(date) {
            const monthNames = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ];
            return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
        },

        getMonthDays(date) {
            const year = date.getFullYear();
            const month = date.getMonth();
            const firstDay = new Date(year, month, 1);
            const lastDay = new Date(year, month + 1, 0);
            const days = [];
            const startPadding = firstDay.getDay();

            for (let i = startPadding - 1; i >= 0; i--) {
                const prevDate = new Date(year, month, -i);
                days.push({
                    date: prevDate,
                    isCurrentMonth: false,
                    isSelected: this.isDateSelected(prevDate),
                    isInRange: this.isDateInRange(prevDate),
                });
            }

            for (let i = 1; i <= lastDay.getDate(); i++) {
                const currentDate = new Date(year, month, i);
                days.push({
                    date: currentDate,
                    isCurrentMonth: true,
                    isSelected: this.isDateSelected(currentDate),
                    isInRange: this.isDateInRange(currentDate),
                });
            }

            const remainingDays = 42 - days.length;
            for (let i = 1; i <= remainingDays; i++) {
                const nextDate = new Date(year, month + 1, i);
                days.push({
                    date: nextDate,
                    isCurrentMonth: false,
                    isSelected: this.isDateSelected(nextDate),
                    isInRange: this.isDateInRange(nextDate),
                });
            }

            return days;
        },

        isDateSelected(date) {
            return (
                this.selectedDateRange.from?.toDateString() ===
                    date.toDateString() ||
                this.selectedDateRange.to?.toDateString() ===
                    date.toDateString()
            );
        },

        isDateInRange(date) {
            if (!this.selectedDateRange.from || !this.selectedDateRange.to)
                return false;
            return (
                date >= this.selectedDateRange.from &&
                date <= this.selectedDateRange.to
            );
        },

        handleDateClick(date) {
            if (this.isFirstClick) {
                this.selectedDateRange = {
                    from: date,
                    to: date,
                };
                this.isFirstClick = false;
            } else {
                if (date < this.selectedDateRange.from) {
                    this.selectedDateRange = {
                        from: date,
                        to: this.selectedDateRange.from,
                    };
                } else {
                    this.selectedDateRange = {
                        from: this.selectedDateRange.from,
                        to: date,
                    };
                }
                this.isFirstClick = true;
            }
            this.$emit("input", this.selectedDateRange);
        },

        handleReset() {
            const today = new Date();
            this.selectedDateRange = { from: null, to: null };
            this.displayedDateRange = this.placeholderText;
            this.currentMonth = today.getMonth();
            this.currentYear = today.getFullYear();
            this.isFirstClick = true;
            this.$emit("input", this.selectedDateRange);
        },

        handleSave() {
            this.isOpen = false;
            this.displayedDateRange = this.selectedDateRangeText;
            this.$emit("input", this.selectedDateRange);
            this.$emit("save", this.selectedDateRange);
        },

        previousMonth() {
            if (this.currentMonth === 0) {
                this.currentMonth = 11;
                this.currentYear--;
            } else {
                this.currentMonth--;
            }
        },

        nextMonth() {
            if (this.currentMonth === 11) {
                this.currentMonth = 0;
                this.currentYear++;
            } else {
                this.currentMonth++;
            }
        },
    },
};
</script>
