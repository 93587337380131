<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_load_data"></div>
        <div id="loader" v-if="is_load_data"></div>
        <div class="box mt-4 m-mt-0">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold">List Data Order</h3>
            </div>
            <div class="d-flex mt-4">
                <div class="me-3">
                    <div class="position-relative">
                        <input
                            type="text"
                            class="form-control form-control-lg pl-search w-search"
                            placeholder="Cari nomor resi"
                            @keyup.enter="changeSearch"
                            v-model="req.search"
                        />
                        <span class="search-icon left">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
                <div class="m-mt-1 ms-3">
                    <a
                        href="/trawltruck/ho/order/create"
                        class="btn btn-green btn-filter px-4"
                    >
                        <i class="fa fa-plus me-1"></i>
                        Buat Project On Call
                    </a>
                </div>
                <div class="m-mt-1 ms-3">
                    <button
                        class="btn btn-outline-black btn-filter px-4"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
                <div class="ms-auto">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Rows per page</div>
                        <div>
                            <select
                                class="form-control form-select w-select"
                                v-model="per_page"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="row">
                    <div class="col-md-3 mb-2">
                        <label class="fw-bold mb-1">Tanggal Order </label>
                        <date-picker
                            v-model="start_date"
                            range
                            type="date"
                            value-type="format"
                            class="w-100"
                            format="DD MMMM YYYY"
                            :clearable="false"
                            placeholder="Pilih tanggal order"
                            @change="getStartDate(start_date)"
                        >
                        </date-picker>
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="fw-bold mb-1">Jenis Mitra </label>
                        <multiselect
                            v-model="partner"
                            :options="partners"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            placeholder="Pilih jenis mitra"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="fw-bold mb-1">Metode Pembayaran </label>
                        <multiselect
                            v-model="type_payment"
                            :options="type_payments"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            placeholder="Pilih pembayaran"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-3 mb-2">
                        <label class="fw-bold mb-1">Jenis Pesanan </label>
                        <multiselect
                            v-model="create_via"
                            :options="create_vias"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            placeholder="Pilih jenis pesanan"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-6">
                                <button
                                    class="btn btn-outline-black w-100"
                                    :disabled="
                                        start_date == '' &&
                                        partner == null &&
                                        type_payment == null &&
                                        create_via == null
                                    "
                                    @click="clearFilter()"
                                >
                                    Clear
                                </button>
                            </div>
                            <div class="col-md-6">
                                <button
                                    class="btn btn-green w-100"
                                    :disabled="
                                        start_date == '' &&
                                        partner == null &&
                                        type_payment == null &&
                                        create_via == null
                                    "
                                    @click="filter()"
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_list_ready">
                <ListLoader />
            </div>
            <div v-else>
                <template v-if="list.list_data.length > 0">
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Nomor Resi
                                    </th>
                                    <th class="text-center" scope="col">
                                        Rute
                                    </th>
                                    <th class="text-center" scope="col">
                                        Nama Mitra
                                    </th>
                                    <th class="text-center" scope="col">
                                        Jadwal Pesanan
                                    </th>
                                    <th class="text-center" scope="col">
                                        Jenis Pesanan
                                    </th>
                                    <th class="text-center" scope="col">
                                        Metode Pembayaran
                                    </th>
                                    <th class="text-center" scope="col">
                                        Status Pembayaran
                                    </th>
                                    <th class="text-center" scope="col">
                                        Tanggal Order
                                    </th>
                                </tr>
                            </thead>

                            <template v-for="(data, index) in list.list_data">
                                <tbody :key="index">
                                    <tr>
                                        <td class="text-center fw-bold">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="text-center">
                                            <a
                                                :href="
                                                    '/trawltruck/ho/order/detail/' +
                                                    data.id
                                                "
                                                v-if="
                                                    data.payment_status ==
                                                        'pending' ||
                                                    data.payment_status ==
                                                        'draft'
                                                "
                                                class="text-link"
                                            >
                                                {{ data.code }}
                                            </a>
                                            <a
                                                :href="
                                                    '/trawltruck/ho/order-detail/' +
                                                    data.id
                                                "
                                                v-else
                                                class="text-link"
                                            >
                                                {{ data.code }}
                                            </a>
                                        </td>
                                        <td>
                                            <a
                                                href="javascript:void(0)"
                                                class="text-dark"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                :title="data.route"
                                            >
                                                {{ data.route }}
                                            </a>
                                        </td>
                                        <td>
                                            {{
                                                data.partner_truck
                                                    ? data.partner_truck
                                                    : "-"
                                            }}
                                        </td>
                                        <td class="text-center">
                                            {{ data?.beginpickup_at ?? "-" }}
                                        </td>
                                        <td class="text-center capitalize">
                                            {{ data.create_via }}
                                        </td>
                                        <td class="text-center capitalize">
                                            {{ data.payment_method }}
                                        </td>
                                        <td class="text-center">
                                            <div
                                                class="capitalize radius"
                                                v-bind:class="{
                                                    'tag green-cro mb-0 pb-0 w-100':
                                                        data.payment_status ==
                                                        'paid',
                                                    'tag yellow-cro mb-0 pb-0 w-100':
                                                        data.payment_status ==
                                                        'pending',
                                                    'tag yellow-cro mb-0 pb-0 w-100':
                                                        data.payment_status ==
                                                        'draft',
                                                }"
                                            >
                                                <template
                                                    v-if="
                                                        data.payment_status ==
                                                            'draft' ||
                                                        data.payment_status ==
                                                            'pending'
                                                    "
                                                >
                                                    Unpaid
                                                </template>
                                                <template v-else>
                                                    {{
                                                        data.payment_status
                                                            ? data.payment_status
                                                            : "-"
                                                    }}
                                                </template>
                                            </div>
                                            <div
                                                class="mt-1"
                                                v-if="
                                                    data.payment_status ==
                                                    'draft'
                                                "
                                            >
                                                <a
                                                    href="javascript:void(0)"
                                                    @click="
                                                        showModalVA(data.id)
                                                    "
                                                    class="text-green"
                                                    v-if="
                                                        data.payment_method ==
                                                        'va'
                                                    "
                                                >
                                                    <u> View VA </u>
                                                </a>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{ data.order_date }}
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody class="padding-body" :key="data.id">
                                    <tr>
                                        <td class="p-footer" colspan="9">
                                            <div
                                                class="d-flex align-items-center"
                                            >
                                                <div
                                                    class="fw-semibold size-12"
                                                    v-bind:class="{
                                                        'text-danger':
                                                            data.status ==
                                                            'cancel',
                                                        'text-green':
                                                            data.status ==
                                                            'done',
                                                        'text-gray':
                                                            data.status ==
                                                            'pending',
                                                        'text-yellow':
                                                            data.status ==
                                                            'waiting',
                                                    }"
                                                >
                                                    <i
                                                        class="fa fa-circle me-1"
                                                    ></i>
                                                    {{ data.status_label }}
                                                </div>
                                                <div class="ms-auto">
                                                    <button
                                                        class="btn btn-yellow btn-sm px-3"
                                                        v-if="
                                                            data.payment_status !=
                                                                'paid' &&
                                                            data.status !=
                                                                'cancel'
                                                        "
                                                        @click="
                                                            getEditMitra(
                                                                data.id
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="fa fa-pencil me-1"
                                                        ></i>
                                                        Ubah Mitra
                                                    </button>
                                                    <button
                                                        class="btn btn-green btn-sm px-3"
                                                        :disabled="
                                                            data.can_download_invoice ==
                                                                true ||
                                                            is_download ===
                                                                index
                                                        "
                                                        v-if="
                                                            data.payment_status ==
                                                            'paid'
                                                        "
                                                        @click="
                                                            downloadFaktur(
                                                                data.id,
                                                                index
                                                            )
                                                        "
                                                    >
                                                        <i
                                                            class="fa fa-download me-1"
                                                        ></i>
                                                        <span
                                                            v-if="
                                                                is_download ===
                                                                index
                                                            "
                                                            class="spinner-border spinner-border-sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        >
                                                        </span>
                                                        Download Faktur
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </table>
                    </div>
                </template>
                <template v-else>
                    <div class="mt-5">
                        <center>
                            <img
                                src="../../assets/no-data.png"
                                width="100"
                                alt=""
                            />
                            <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                                Data Kosong
                            </h4>
                        </center>
                    </div>
                </template>
            </div>
            <div class="d-flex mt-3" v-if="list.list_data.length > 0">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-model="currentPage"
                        :number-of-pages="list.total_page"
                        base-url="#"
                        first-number
                        align="right"
                        @input="changePage"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <b-modal v-model="modalVA" size="lg" hide-footer hide-header>
            <div class="p-3">
                <h4 class="fw-bold d-flex align-items-center mb-0">
                    <i class="fa fa-file me-2 size-18"></i>
                    Lakukan Pembayaran
                </h4>
                <div class="box no-shadow border-gray mt-4 p-3">
                    <h5 class="mb-3 fw-bold">Rincian Pembayaran</h5>
                    <div class="d-flex align-items-center">
                        <div>
                            <h6 class="mb-0 size-16">Total Pembayaran</h6>
                        </div>
                        <div class="ms-auto">
                            <div class="text-green fw-bold">
                                <span class="size-18" ref="paymentCopy">
                                    {{
                                        currency(
                                            detail.payment.total_payment
                                                ? detail.payment.total_payment
                                                : 0
                                        )
                                    }}
                                </span>
                                <a
                                    href="javascript:void(0)"
                                    @click="paymentCopy()"
                                >
                                    <img
                                        src="../../assets/clone.png"
                                        width="20"
                                        class="ms-1"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex">
                        <div>
                            <h6 class="mb-0 size-16">Jatuh Tempo</h6>
                        </div>
                        <div class="ms-auto">
                            <template
                                v-if="
                                    moment(detail.payment.expired_at).format(
                                        'YYYY-MM-DD hh:mm:ss'
                                    ) < moment().format('YYYY-MM-DD hh:mm:ss')
                                "
                            >
                                <div class="size-18 text-primary fw-bold">
                                    {{ expired }}
                                </div>
                            </template>
                            <template v-else>
                                <div
                                    id="countdown"
                                    class="size-18 text-primary fw-bold"
                                ></div>
                            </template>
                            <div class="text-yellow">
                                Jatuh tempo
                                {{
                                    moment(detail.payment.expired_at).format(
                                        "DD MMMM YYYY hh:mm:ss"
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex align-items-center">
                        <div>
                            <h6 class="mb-0 size-16">Metode Pembayaran</h6>
                        </div>
                        <div class="ms-auto">
                            <div class="size-18 fw-bold capitalize">
                                <template v-if="detail.type_payment == 'va'">
                                    Virtual Account
                                </template>
                                <template v-else>
                                    {{
                                        detail.type_payment
                                            ? detail.type_payment
                                            : "-"
                                    }}
                                </template>
                                -
                                {{
                                    detail.payment.channel_name
                                        ? detail.payment.channel_name
                                        : "-"
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box no-shadow border-gray mt-3 p-3">
                    <h5 class="mb-3 fw-bold">Nomor Virtual Account</h5>
                    <div class="box-va">
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <img
                                    :src="detail.payment.icon"
                                    width="50"
                                    alt=""
                                />
                            </div>
                            <div class="fw-semibold size-16" ref="VACopy">
                                {{ detail.payment.payment_ref_content }}
                            </div>
                            <div class="ms-auto">
                                <a
                                    href="javascript:void(0)"
                                    class="text-green size-16"
                                    @click="VACopy()"
                                >
                                    Salin
                                    <img
                                        src="../../assets/clone.png"
                                        width="20"
                                        class="ms-1"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 text-green size-13">
                        Bayar pesanan ke Virtual Account di atas sebelum membuat
                        pesanan kembali dengan Virtual Account agar nomor tetap
                        sama.
                    </div>
                    <div class="mt-2">
                        <button
                            class="btn btn-green w-100"
                            @click="(modalVaCreate = true), (modalVA = false)"
                        >
                            Buat VA Baru
                        </button>
                    </div>
                </div>
                <div
                    class="box no-shadow bc-light-yellow mt-3 p-3 border-small"
                >
                    <img
                        src="../../assets/info-new.png"
                        width="16"
                        class="me-2"
                        alt=""
                    />
                    Proses verifikasi 10 menit setelah pembayaran berhasil
                </div>
                <div class="box no-shadow border-gray mt-3 p-3">
                    <h5 class="mb-3 fw-bold">Petunjuk Pembayaran</h5>
                    <div class="accordion" role="tablist">
                        <b-card
                            no-body
                            v-for="(step, index) in payment_procedure"
                            :key="index"
                        >
                            <b-card-header
                                header-tag="header"
                                class="p-1"
                                role="tab"
                            >
                                <div
                                    class="d-flex align-items-center"
                                    block
                                    v-b-toggle="'accordion-' + index"
                                    variant="info"
                                >
                                    <div class="size-14 fw-bold">
                                        {{ step.payment_channel }}
                                    </div>
                                    <div class="ms-auto">
                                        <i class="fa fa-angle-down size-18"></i>
                                    </div>
                                </div>
                            </b-card-header>
                            <b-collapse
                                :id="'accordion-' + index"
                                accordion="my-accordion"
                                role="tabpanel"
                            >
                                <b-card-body>
                                    <div v-html="step.payment_procedure"></div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal v-model="modalVaCreate" size="lg" hide-footer hide-header>
            <div class="p-3">
                <a
                    href="javascript:void(0)"
                    class="text-dark"
                    @click="(modalVaCreate = false), (modalVA = true)"
                >
                    <h4 class="fw-bold d-flex align-items-center mb-0">
                        <i class="fa fa-angle-left me-2 size-18"></i>
                        Buat VA Baru
                    </h4>
                </a>
                <div class="box no-shadow border-gray mt-3 p-3">
                    <h5 class="mb-3 fw-bold">Bank pembayaran sebelumnya</h5>
                    <div class="box-va">
                        <div class="d-flex align-items-center">
                            <div class="me-3">
                                <img
                                    :src="detail.payment.icon"
                                    width="50"
                                    alt=""
                                />
                            </div>
                            <div class="fw-semibold size-16" ref="VACopy">
                                {{ detail.payment.payment_ref_content }}
                            </div>
                        </div>
                    </div>
                    <h5 class="mb-0 fw-bold mt-3">Pilih bank pembayaran</h5>
                    <div class="text-gray">
                        Pilih bank untuk membuat Virtual Account baru
                    </div>
                    <div class="mt-2">
                        <multiselect
                            v-model="value"
                            placeholder="Pilih Bank"
                            label="full_name_label"
                            track-by="id"
                            :options="options"
                        >
                            <template #singleLabel="props">
                                <div class="d-flex align-items-center">
                                    <img :src="props.option.icon" width="40" />
                                    <div class="ms-2">
                                        {{ props.option.full_name_label }}
                                    </div>
                                </div>
                            </template>
                            <template #option="props">
                                <div class="d-flex align-items-center">
                                    <img :src="props.option.icon" width="40" />
                                    <div class="ms-2">
                                        {{ props.option.full_name_label }}
                                    </div>
                                </div>
                            </template>
                        </multiselect>
                    </div>
                </div>
                <div class="mt-3">
                    <button
                        class="btn btn-green w-100"
                        :disabled="value == null || is_create_va"
                        @click="createVa()"
                    >
                        <span
                            v-if="is_create_va"
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        >
                        </span>
                        Simpan
                    </button>
                </div>
            </div>
        </b-modal>
        <b-modal v-model="modalFleet" size="md" hide-footer hide-header>
            <div class="p-3">
                <h4 class="fw-bold d-flex align-items-center mb-0">
                    Mitra Penjemputan
                </h4>
                <div class="mt-3">
                    <table class="w-100 ps-0 fw-bold mt-4" cellpadding="10">
                        <tr>
                            <td class="ps-0 w-120">Mitra Pickup</td>
                            <td class="w-30">:</td>
                            <td>
                                <multiselect
                                    v-model="assign_order"
                                    :options="fleets"
                                    label="name"
                                    track-by="id"
                                    placeholder="Pilih mitra"
                                    class="multi-form-custom"
                                    :show-labels="false"
                                >
                                </multiselect>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-120">Komisi Mitra</td>
                            <td class="w-30">:</td>
                            <td>
                                <money
                                    v-model="commission_partner"
                                    v-bind="format_number"
                                    class="form-control w-koli"
                                    placeholder="Komisi Mitra"
                                >
                                </money>
                            </td>
                        </tr>
                        <tr class="border-top">
                            <td class="ps-0 w-120">Helper</td>
                            <td class="w-30">:</td>
                            <td>
                                <money
                                    v-model="additional_price"
                                    v-bind="format_number"
                                    class="form-control w-koli"
                                    placeholder="Helper"
                                >
                                </money>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="mt-2">
                    <button
                        class="btn btn-green w-100"
                        :disabled="assign_order == null || is_store_mitra"
                        @click="storeMitra()"
                    >
                        <span
                            class="spinner-border spinner-border-sm me-2"
                            v-if="is_store_mitra"
                        ></span>
                        Simpan Perubahan
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import moment from "moment";
import { Tooltip } from "bootstrap";
import Multiselect from "vue-multiselect";
import { Money } from "v-money";
import DatePicker from "vue2-datepicker";

export default {
    components: {
        ListLoader,
        Multiselect,
        Money,
        DatePicker,
    },

    name: "ManagementHoOrder",
    data() {
        return {
            moment: moment,
            is_list_ready: false,
            list: {
                list_data: [],
                next_page: 0,
                total_data: 0,
                total_page: 0,
                current_page: 0,
            },
            per_page: 10,
            currentPage: 0,
            req: {
                search: "",
            },
            modalVA: false,
            modal_id: null,
            detail: {
                payment: {},
            },
            payment_procedure: [],
            is_download: null,
            modalVaCreate: false,
            value: null,
            options: [],
            is_create_va: false,
            fleets: [],
            fleet_type: null,
            modalFleet: false,
            is_load_data: false,
            assign_order: null,
            format_number: {
                decimal: ",",
                thousands: ".",
                prefix: "Rp",
                suffix: "",
                precision: 0,
                masked: false,
                maxLength: 13,
                minimumValue: 0,
            },
            commission_partner: 0,
            additional_price: 0,
            is_store_mitra: false,
            isFilter: false,
            start_date: "",
            partner: null,
            partners: [
                { name: "Individu", id: "individual" },
                { name: "Corporate", id: "partner" },
            ],
            type_payment: null,
            type_payments: [
                { name: "Va", id: "va" },
                { name: "Cash", id: "cash" },
            ],
            create_via: null,
            create_vias: [
                { name: "App", id: "app" },
                { name: "On Call", id: "onc" },
                { name: "Truk Standby", id: "std" },
            ],
        };
    },
    created() {
        this.getList();
        this.getBank();
    },
    mounted() {
        new Tooltip(document.body, {
            selector: "[data-bs-toggle='tooltip']",
        });
    },
    computed: {},
    methods: {
        changePage() {
            this.getList();
        },
        changeSearch() {
            this.currentPage = 0;
            this.getList();
        },
        getList() {
            this.is_list_ready = true;
            let payload = {
                search: this.req.search,
                limit: this.per_page,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 1,
            };
            if (this.start_date) {
                payload.start_date = moment(this.start_date[0]).format(
                    "YYYY-MM-DD"
                );
                payload.end_date = moment(this.start_date[1]).format(
                    "YYYY-MM-DD"
                );
            }
            if (this.partner) {
                payload.type_driver = this.partner.id;
            }
            if (this.type_payment) {
                payload.type_payment = this.type_payment.id;
            }
            if (this.create_via) {
                payload.create_via = this.create_via.id;
            }
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order`,
                {
                    params: payload,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.list = data;
                    this.is_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_list_ready = false;
                });
        },
        clearFilter() {
            this.start_date = "";
            this.partner = null;
            this.type_payment = null;
            this.create_via = null;
            this.getList();
        },
        filter() {
            this.getList();
        },
        getStartDate(value) {
            this.start_date = value;
        },
        showModalVA(id) {
            this.modalVA = true;
            this.modal_id = id;
            this.getDetail();
        },
        getDetail() {
            this.is_load_data = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/detail?id=${this.modal_id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.detail = data;
                    if (
                        moment(data.payment.expired_at).format(
                            "YYYY-MM-DD hh:mm:ss"
                        ) < moment().format("YYYY-MM-DD hh:mm:ss")
                    ) {
                        this.expired = "Expired";
                    } else {
                        const element = document.getElementById("countdown");
                        const exp = moment(data.payment.expired_at);

                        setInterval(function () {
                            let now = moment().format();
                            let diffDuration = moment.duration(exp.diff(now));
                            element.innerHTML =
                                diffDuration.days() +
                                " " +
                                "hari" +
                                " " +
                                diffDuration.hours() +
                                " " +
                                "jam" +
                                " " +
                                diffDuration.minutes() +
                                " " +
                                "menit" +
                                " " +
                                diffDuration.seconds() +
                                " " +
                                "detik";
                        }, 1000);
                    }
                    this.getPaymentProcedure();
                    this.is_load_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_data = false;
                });
        },
        getPaymentProcedure() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/general/payment/procedure?bank_id=${this.detail.payment.gateway_id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.payment_procedure = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        paymentCopy() {
            const text = this.$refs.paymentCopy.innerText;
            navigator.clipboard.writeText(text);
            this.$notify({
                group: "foo",
                type: "success",
                text: "Total pembayaran berhasil dicopy",
            });
        },
        VACopy() {
            const text = this.$refs.VACopy.innerText;
            navigator.clipboard.writeText(text);
            this.$notify({
                group: "foo",
                type: "success",
                text: "Nomor virtual account berhasil dicopy",
            });
        },
        downloadFaktur(id, index) {
            this.is_download = index;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/invoice/truck/download?order_id=${id}`,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    window.open(res.data.data.file_url, "_blank");
                    this.is_download = null;
                })
                .catch((err) => {
                    console.log(err.response, "err");
                    this.is_download = null;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        getBank() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/oncall/payment`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.options = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        createVa() {
            this.is_create_va = true;
            var data = {
                order_id: this.modal_id,
                payment_channel: this.value?.id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/regenerate_va`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.is_create_va = false;
                    this.getDetail();
                    this.modalVaCreate = false;
                    this.modalVA = true;
                    this.value = null;
                })
                .catch((err) => {
                    this.is_create_va = false;
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                });
        },
        getEditMitra(id) {
            this.modal_id = id;
            this.getDetailAfterEdit();
            this.assign_order = null;
        },
        getDetailAfterEdit() {
            this.is_load_data = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/detail?id=${this.modal_id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.type_fleet = res.data.data.type_fleet;
                    this.commission_partner = res.data.data.income_partner;
                    this.additional_price = res.data.data.additional_price;
                    this.getFleets();
                    this.is_load_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_data = false;
                });
        },
        getFleets() {
            this.is_load_data = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/oncall/partner?type_fleet=${this.type_fleet}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.fleets = res.data.data;
                    this.modalFleet = true;
                    this.is_load_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_data = false;
                });
        },
        storeMitra() {
            this.is_store_mitra = true;
            var data = {
                order_id: this.modal_id,
                assign_order: this.assign_order?.id,
                commission_partner: this.commission_partner,
                additional_price: this.additional_price,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/head-office/trawltruck/order/update_assign`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.modalFleet = false;
                    this.is_store_mitra = false;
                    this.getList();
                    this.$notify({
                        group: "foo",
                        type: "success",
                        title: "Success",
                        text: "Mitra berhasil diupdate",
                    });
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error",
                            text: err.response.data.data.message,
                        });
                    }
                    this.is_store_mitra = false;
                });
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
    vertical-align: middle;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.text-red-dashboard {
    color: #e60013;
}
.search-icon {
    font-size: 16px;
    top: 7px;
}
.dropdown-toggle::after {
    display: none;
}
.dropdown-menu.show {
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border: 1px solid #f7f7f7;
    border-radius: 8px;
}
.padding-body::after {
    height: 10px;
    display: table-row;
    content: "";
}
.box-va {
    border: 1px solid #d2d2d5;
    border-radius: 10px;
    padding: 8px 20px;
    background: #fbfbfb;
    color: #000;
}
.payment-li {
    line-height: 25px;
}
.card-header {
    background-color: #fff;
    color: #000;
    border-bottom: 1px solid #f4f6f7;
    padding: 5px 0px !important;
}
.card {
    border: unset;
}
.card-body {
    padding: 0px;
    padding-top: 6px;
    border-bottom: 1px solid #f4f6f7;
}
.w-120 {
    width: 120px;
}
.w-30 {
    width: 30px;
}
</style>
