<template>
    <div class="month-picker" ref="monthPicker">
        <button
            class="form-control w-100 text-left d-flex align-items-center text-placeholder"
            @click="isOpen = !isOpen"
        >
            <svg
                data-v-26837f1d=""
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="19"
                viewBox="0 0 25 24"
                fill="none"
                class="me-2"
            >
                <path
                    data-v-26837f1d=""
                    d="M3.3335 16.5V8.5C3.3335 6.01472 5.34821 4 7.8335 4H16.8335C19.3188 4 21.3335 6.01472 21.3335 8.5V16.5C21.3335 18.9853 19.3188 21 16.8335 21H7.8335C5.34821 21 3.3335 18.9853 3.3335 16.5Z"
                    fill="#61616A"
                    stroke="#61616A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    data-v-26837f1d=""
                    d="M9.3335 2.5V5.5"
                    stroke="#61616A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    data-v-26837f1d=""
                    d="M15.3335 2.5V5.5"
                    stroke="#61616A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                ></path>
                <path
                    data-v-26837f1d=""
                    d="M3.3335 9H21.3335"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="square"
                ></path>
                <circle
                    data-v-26837f1d=""
                    cx="8.3335"
                    cy="13"
                    r="1"
                    fill="white"
                ></circle>
                <circle
                    data-v-26837f1d=""
                    cx="12.3335"
                    cy="13"
                    r="1"
                    fill="white"
                ></circle>
                <circle
                    data-v-26837f1d=""
                    cx="16.3335"
                    cy="13"
                    r="1"
                    fill="white"
                ></circle>
                <circle
                    data-v-26837f1d=""
                    cx="8.3335"
                    cy="17"
                    r="1"
                    fill="white"
                ></circle>
                <circle
                    data-v-26837f1d=""
                    cx="12.3335"
                    cy="17"
                    r="1"
                    fill="white"
                ></circle>
                <circle
                    data-v-26837f1d=""
                    cx="16.3335"
                    cy="17"
                    r="1"
                    fill="white"
                ></circle>
            </svg>
            {{ displayPlaceholder }}
        </button>
        <div v-if="isOpen" class="month-picker-popup card">
            <div class="card-body">
                <div class="calendar-container rounded">
                    <div>
                        <div
                            class="d-flex justify-content-between align-items-center mb-4"
                        >
                            <svg
                                @click="previousYear"
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="10"
                                viewBox="0 0 6 10"
                                fill="none"
                                class="cursor-pointer"
                            >
                                <path
                                    d="M4.83869 9.09438L0.775391 5.03605L4.83869 0.977722"
                                    stroke="#1F1E2C"
                                    stroke-width="1.3536"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <h6 class="current-month-year mb-0 fw-bold">
                                {{ displayYear }}
                            </h6>
                            <svg
                                @click="nextYear"
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="10"
                                viewBox="0 0 6 10"
                                fill="none"
                                class="cursor-pointer"
                            >
                                <path
                                    d="M0.793213 9.09432L4.85651 5.03599L0.793213 0.977661"
                                    stroke="#1F1E2C"
                                    stroke-width="1.3536"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <hr class="divider mt-2 mb-3" />
                    </div>
                    <div class="month-grid">
                        <div
                            v-for="(month, index) in months"
                            :key="month"
                            class="month-cell"
                            :class="{ selected: index === selectedMonth }"
                            @click="selectMonth(index)"
                        >
                            {{ month }}
                        </div>
                    </div>
                    <div class="mt-3 small">
                        <p class="mb-1">
                            Periode terpilih:
                            <span class="text-green">
                                {{ selectedPeriodText }}
                            </span>
                        </p>
                    </div>
                    <div class="row mt-3">
                        <div class="col-6">
                            <button
                                class="btn btn-outline-yellow w-100"
                                @click="handleReset"
                            >
                                Reset
                            </button>
                        </div>
                        <div class="col-6">
                            <button
                                class="btn btn-green w-100"
                                @click="handleSave"
                            >
                                Simpan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MonthPicker",

    props: {
        value: {
            type: Object,
            default: () => ({
                month: null,
                year: null,
            }),
        },
        placeholderText: {
            type: String,
            default: "Pilih bulan",
        },
    },

    data() {
        return {
            savedMonth:
                this.value?.month !== null ? this.value.month - 1 : null,
            savedYear: this.value?.year || null,
            isOpen: false,
            currentYear: this.value?.year || null,
            selectedMonth:
                this.value?.month !== null ? this.value.month - 1 : null,
            months: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            fullMonths: [
                "Januari",
                "Februari",
                "Maret",
                "April",
                "Mei",
                "Juni",
                "Juli",
                "Agustus",
                "September",
                "Oktober",
                "November",
                "Desember",
            ],
        };
    },

    computed: {
        formattedMonthNumber() {
            if (
                this.selectedMonth === null ||
                this.selectedMonth === undefined
            ) {
                return null;
            }
            return String(this.selectedMonth + 1).padStart(2, "0");
        },

        displayYear() {
            return this.currentYear || new Date().getFullYear();
        },

        displayPlaceholder() {
            console.log(this.savedMonth);
            console.log(this.savedYear);
            if (this.savedMonth === null || this.savedYear === null) {
                return this.placeholderText;
            }
            return `${this.fullMonths[this.savedMonth]} ${this.savedYear}`;
        },
        selectedMonthText() {
            if (
                this.selectedMonth === null ||
                this.selectedMonth === undefined
            ) {
                return this.placeholderText;
            }
            const year = this.currentYear || new Date().getFullYear();
            return `${this.fullMonths[this.selectedMonth]} ${year}`;
        },

        selectedPeriodText() {
            if (
                this.selectedMonth === null ||
                this.selectedMonth === undefined
            ) {
                return "-";
            }
            const year = this.currentYear || new Date().getFullYear();
            return `${this.fullMonths[this.selectedMonth]} ${year}`;
        },
    },

    watch: {
        value: {
            handler(newValue) {
                if (newValue) {
                    this.selectedMonth =
                        newValue.month !== null ? newValue.month - 1 : null;
                    this.currentYear = newValue.year ?? null;
                    this.savedMonth =
                        newValue.month !== null ? newValue.month - 1 : null;
                    this.savedYear = newValue.year ?? null;
                }
            },
            immediate: true,
            deep: true,
        },
    },

    created() {
        if (this.selectedMonth === undefined) {
            this.selectedMonth = null;
        }
        if (this.currentYear === undefined) {
            this.currentYear = null;
        }
    },

    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },

    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
    },

    methods: {
        handleClickOutside(event) {
            const monthPicker = this.$refs.monthPicker;
            if (
                monthPicker &&
                !monthPicker.contains(event.target) &&
                this.isOpen
            ) {
                this.isOpen = false;
            }
        },

        selectMonth(monthIndex) {
            this.selectedMonth = monthIndex;
            if (this.currentYear === null) {
                this.currentYear = new Date().getFullYear();
            }
        },

        handleReset() {
            this.savedMonth = null;
            this.savedYear = null;
            this.selectedMonth = null;
            this.currentYear = null;
            this.$emit("input", {
                month: null,
                year: null,
            });
        },

        handleSave() {
            this.savedMonth = this.selectedMonth;
            this.savedYear = this.currentYear || new Date().getFullYear();
            this.isOpen = false;
            const year = this.currentYear || new Date().getFullYear();
            const monthNumber =
                this.selectedMonth !== null ? this.selectedMonth + 1 : null;

            const result = {
                month: monthNumber,
                year: year,
                formattedMonth: this.formattedMonthNumber,
            };

            this.$emit("input", result);
            this.$emit("save", result);
        },

        previousYear() {
            if (this.currentYear === null) {
                this.currentYear = new Date().getFullYear();
            }
            this.currentYear--;
        },

        nextYear() {
            if (this.currentYear === null) {
                this.currentYear = new Date().getFullYear();
            }
            this.currentYear++;
        },
    },
};
</script>

<style scoped>
.month-picker {
    position: relative;
    width: 100%;
    max-width: 800px;
}

.text-placeholder {
    color: #6c757d !important;
    font-size: 13px;
}

.month-picker-popup {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    width: 24rem;
    margin-top: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.month-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    padding: 1rem 0;
}

.month-cell {
    text-align: center;
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 4px;
}

.month-cell:hover {
    background-color: #d8e7d3;
}

.month-cell.selected {
    background-color: #3d8824;
    color: white;
}

.divider {
    border: none;
    border-top: 1px solid #dee2e6;
    margin: 0;
    width: 100%;
}

.calendar-container {
    background: white;
    padding: 1rem;
}

.btn-outline-yellow {
    color: #3d8824;
    background-color: transparent;
    border: 1px solid #3d8824;
}

.btn-outline-yellow:hover {
    color: #3d8824;
    background-color: transparent;
    border: 1px solid #3d8824;
}

.btn-green {
    background-color: #3d8824;
    color: white;
    border: 1px solid #3d8824;
}

.btn-green:hover {
    background-color: #357320;
    color: white;
    border: 1px solid #357320;
}

.text-green {
    color: #3d8824;
}

.cursor-pointer {
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .month-picker-popup {
        width: 24rem;
        max-width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .card-body {
        min-width: 24rem;
        padding-bottom: 1rem;
    }
}
</style>
