<template>
    <div class="padding-container">
        <div class="box">
            <div class="mb-3">
                <h3 class="fw-bold">Account Driver</h3>
            </div>
            <button
                class="btn btn-outline-black btn-filter px-4"
                @click="isFilter = !isFilter"
            >
                <img
                    src="../../assets/dashboard/filter.png"
                    class="me-1"
                    width="18"
                    alt=""
                />
                Filter
            </button>
            <div class="box mt-3" v-if="isFilter">
                <label class="fw-bold">Filter Data</label>
                <div class="row">
                    <div class="col-md-6 mt-2">
                        <input
                            type="text"
                            class="form-control w-search-leads"
                            v-model="search"
                            placeholder="Masukkan Nama atau Nomor Telepon"
                        />
                    </div>
                    <div class="col-md-6 mt-2">
                        <multiselect
                            v-model="armada"
                            :options="armadas"
                            label="name"
                            :loading="is_load"
                            track-by="code"
                            placeholder="Pilih Armada"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-2">
                        <multiselect
                            v-model="pic"
                            :options="pics"
                            label="name"
                            :loading="is_load"
                            track-by="id"
                            placeholder="Pilih Pic"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-6 mt-2">
                        <select
                            class="form-control form-select"
                            v-model="filter"
                        >
                            <option value="">Pilih Urutan</option>
                            <option value="desc">Terbaru</option>
                            <option value="asc">Terlama</option>
                        </select>
                    </div>
                    <div class="col-md-6 mt-2">
                        <multiselect
                            v-model="regency"
                            :options="regencies"
                            label="name"
                            :loading="is_load_regency"
                            track-by="id"
                            placeholder="Pilih kota/kabupaten"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-md-2 mt-2">
                        <button
                            class="btn btn-outline-black px-5 w-100"
                            :disabled="
                                search == '' &&
                                armada == null &&
                                pic == null &&
                                filter == '' &&
                                regency == null
                            "
                            @click="clearFilter()"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-md-2 mt-2">
                        <button
                            class="btn btn-green px-5 w-100"
                            :disabled="
                                search == '' &&
                                armada == null &&
                                pic == null &&
                                filter == '' &&
                                regency == null
                            "
                            @click="submitFilter()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom mt-4">
                    <table class="table table-bordered">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <!-- <th class="text-center" scope="col">
                                    ID Mitra
                                </th> -->
                                <th class="text-center" scope="col">
                                    Nama Driver
                                </th>
                                <th class="text-center" scope="col">
                                    Nomor Telepon
                                </th>
                                <th class="text-center" scope="col">PIC</th>
                                <th class="text-center" scope="col">
                                    Nomor Polisi
                                </th>
                                <th class="text-center" scope="col">
                                    Jenis Armada
                                </th>
                                <th class="text-center" scope="col">
                                    Kota/Kabupaten
                                </th>
                                <th class="text-center" scope="col">
                                    Tanggal Bergabung
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in data.list_data"
                                :key="index"
                            >
                                <td class="text-center" style="width: 50px">
                                    {{ index + 1 }}
                                </td>
                                <td class="">
                                    <router-link
                                        class="text-link"
                                        :to="{
                                            name: 'detail-account-driver',
                                            params: { id: item.id },
                                        }"
                                        >{{ item.name }}</router-link
                                    >
                                </td>
                                <td class="text-center" style="width: 160px">
                                    {{ item.phone ? item.phone : "-" }}
                                </td>
                                <td>
                                    {{
                                        item.driver_truck?.approver?.name
                                            ? item.driver_truck?.approver?.name
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.driver_truck.fleet?.fleet_number
                                            ? item.driver_truck.fleet
                                                  ?.fleet_number
                                            : "-"
                                    }}
                                </td>
                                <td style="width: 180px">
                                    {{
                                        item.driver_truck.fleet
                                            ?.fleet_type_label
                                            ? item.driver_truck.fleet
                                                  ?.fleet_type_label
                                            : "-"
                                    }}
                                </td>
                                <td>
                                    {{
                                        item.driver_truck?.regency?.name
                                            ? item.driver_truck?.regency?.name
                                            : "-"
                                    }}
                                </td>
                                <td class="text-center">
                                    {{
                                        moment(
                                            item.driver_truck.accepted_at
                                        ).format("DD MMMM YYYY | HH:mm")
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="data.list_data.length == 0"
                    class="text-center mt-section"
                >
                    <img src="../../assets/no-data.png" width="100" alt="" />
                    <h4 class="mb-0 fw-bold mt-4 mb-4 text-gray">
                        Data Kosong
                    </h4>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <div class="d-flex">
                <div class="ms-auto">
                    <div class="d-flex">
                        <div class="me-3">
                            <select
                                class="form-control form-select w-select"
                                v-model="limit"
                                @change="getList()"
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <b-pagination-nav
                            v-if="data.list_data.length > 0"
                            v-model="currentPage"
                            :number-of-pages="data.total_page"
                            base-url="#"
                            first-number
                            align="right"
                            @input="changeSearch"
                        ></b-pagination-nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import Multiselect from "vue-multiselect";
import moment from "moment";

export default {
    components: { ListLoader, Multiselect },
    // name: "Account Driver",
    data() {
        return {
            moment: moment,
            data: {
                list_data: [],
            },
            currentPage: 0,
            limit: 10,
            search: "",
            is_load: false,
            armada: null,
            armadas: [],
            pic: null,
            pics: [],
            filter: "",
            regency: null,
            regencies: [],
            is_load_regency: false,
            isFilter: false,
        };
    },
    created() {
        this.getList();
        this.getListArmada();
        this.getListPic();
        this.getGeoList();
    },
    computed: {},
    mounted() {},
    methods: {
        getGeoList() {
            this.is_load_regency = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                    this.is_load_regency = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_regency = false;
                });
        },
        changeSearch() {
            this.getList();
        },
        getList() {
            this.is_load = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/driver/list-individual`,
                {
                    params: {
                        page:
                            this.currentPage == 0 || this.currentPage == null
                                ? 0
                                : this.currentPage - 1,
                        limit: this.limit,
                        search: this.search,
                        type_fleet: this.armada?.code,
                        regency_id: this.regency?.id,
                        pic: this.pic?.id,
                        filter: this.filter,
                    },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.data = res.data.data;
                    this.is_load = false;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.data.message,
                    });
                    this.is_load = false;
                });
        },
        getListArmada() {
            Api.get(`https://apiv2.trawlbens.com/web/v2/trawltruck/fleet`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.armadas = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getListPic() {
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/admin/trawltruck/approver/list`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    var data = res.data.data;
                    this.pics = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        clearFilter() {
            (this.search = ""), (this.armada = null);
            this.pic = null;
            (this.regency = null), (this.filter = ""), this.getList();
        },
        submitFilter() {
            this.getList();
        },
    },
};
</script>

<style scoped>
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
</style>
